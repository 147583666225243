import React from 'react'
import { Link } from 'react-router-dom'
import SignOutButton from '../../utility/SignOut'
import * as ROUTES from '../../../constants/routes'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExitToApp, LockOpen, People, History } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthUser, logOut } from '../../../store/session.slice'
import { compose } from '@reduxjs/toolkit'
import { withFirebase } from '../../higher-order/Firebase'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import GavelIcon from '@material-ui/icons/Gavel'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import CropFreeIcon from '@material-ui/icons/CropFree'
const Navigation = props => {
  const authUser = useSelector(getAuthUser)

  switch (authUser?.role) {
    case 'ADMIN':
    case 'ACCOUNTHOLDER': {
      return <NavigationAdmin firebase={props.firebase} />
    }
    case 'STATION': {
      return <NavigationAuth firebase={props.firebase} />
    }
    default: {
      return <NavigationNonAuth />
    }
  }
}

const NavigationAdmin = ({ firebase }) => {
  const dispatch = useDispatch()

  const handleSignOut = () => {
    dispatch(logOut({ firebase }))
  }

  return (
    <List>
      <Link to={ROUTES.SCAN}>
        <ListItem button key={ROUTES.SCAN + 'menu-BTN'}>
          <ListItemIcon>
            <CropFreeIcon />
          </ListItemIcon>
          <ListItemText primary='Add Tardy' />
        </ListItem>
      </Link>
      <Link to={ROUTES.STUDENTS}>
        <ListItem button key={ROUTES.STUDENTS + 'menu-BTN'}>
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary='Student List' />
        </ListItem>
      </Link>
      <Link to={ROUTES.TARDIES}>
        <ListItem button key={ROUTES.TARDIES + 'menu-BTN'}>
          <ListItemIcon>
            <FormatListBulletedIcon />
          </ListItemIcon>
          <ListItemText primary='Tardy List' />
        </ListItem>
      </Link>
      <Link to={ROUTES.DETENTIONS}>
        <ListItem button key={ROUTES.DETENTIONS + 'menu-BTN'}>
          <ListItemIcon>
            <AssignmentLateIcon />
          </ListItemIcon>
          <ListItemText primary='Detentions' />
        </ListItem>
      </Link>
      <Link to={ROUTES.OFFICE_VISITS}>
        <ListItem button key={ROUTES.OFFICE_VISITS + 'menu-BTN'}>
          <ListItemIcon>
            <GavelIcon />
          </ListItemIcon>
          <ListItemText primary='Office Referrals' />
        </ListItem>
      </Link>
      <Link to={ROUTES.ACCOUNT_HISTORY}>
        <ListItem button key={ROUTES.ACCOUNT_HISTORY + 'menu-BTN'}>
          <ListItemIcon>
            <History />
          </ListItemIcon>
          <ListItemText primary='Event History' />
        </ListItem>
      </Link>
      <Link to={ROUTES.SIGN_IN} onClick={handleSignOut}>
        <ListItem>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <SignOutButton />
        </ListItem>
      </Link>
    </List>
  )
}

const NavigationNonAuth = () => (
  <List>
    <Link to={ROUTES.SIGN_IN}>
      <ListItem button key={ROUTES.SIGN_IN + 'menu-BTN'}>
        <ListItemIcon>
          <LockOpen />
        </ListItemIcon>
        <ListItemText primary='Sign In' />
      </ListItem>
    </Link>
  </List>
)

const NavigationAuth = ({ firebase }) => {
  const dispatch = useDispatch()

  const handleSignOut = () => {
    dispatch(logOut({ firebase }))
  }

  return (
    <List>
      <Link to={ROUTES.SCAN}>
        <ListItem button key={ROUTES.SCAN + 'menu-BTN'}>
          <ListItemIcon>
            <CropFreeIcon />
          </ListItemIcon>
          <ListItemText primary='Add Tardy' />
        </ListItem>
      </Link>
      <Link to={ROUTES.STUDENTS}>
        <ListItem button key={ROUTES.STUDENTS + 'menu-BTN'}>
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary='Student List' />
        </ListItem>
      </Link>
      <Link to={ROUTES.TARDIES}>
        <ListItem button key={ROUTES.TARDIES + 'menu-BTN'}>
          <ListItemIcon>
            <FormatListBulletedIcon />
          </ListItemIcon>
          <ListItemText primary='Tardy List' />
        </ListItem>
      </Link>
      <Link to={ROUTES.SIGN_IN} onClick={handleSignOut}>
        <ListItem>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <SignOutButton />
        </ListItem>
      </Link>
    </List>
  )
}

export default compose(withFirebase)(Navigation)
