import { Card, CardContent, CardHeader, Container } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import React from 'react'

class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null }

  componentDidCatch(error, errorInfo) {
    this.setState({ error: error, errorInfo: errorInfo })
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      return (
        <Container component='main' maxWidth='md'>
          <div className='mt-4' style={{ display: 'flex', flexDirection: 'column' }}>
            <Card>
              <CardHeader title={this.state.error.message} />
              {this.state.errorInfo && (
                <CardContent>
                  <Alert severity='error'>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                      {this.state.error.toString()}
                      <br />
                      {this.state.errorInfo.componentStack}
                    </details>
                  </Alert>
                </CardContent>
              )}
            </Card>
          </div>
        </Container>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
