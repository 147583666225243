import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  changePassword,
  fetchHistory,
  resetPassword,
  updateAddress,
  updateClassPeriods,
  updateConsequences,
  updateMessaging,
} from './account.slice'
import {
  deleteAllDetentions,
  deleteDetention,
  completeDetention,
  addDetention,
} from './detentions.slice'
import {
  addOfficeVisit,
  completeOfficeVisit,
  deleteAllOfficeVisits,
  deleteOfficeVisit,
} from './officeVisits.slice'
import { fetchPrinters, logIn, printTestPage, signUp } from './session.slice'
import {
  deleteAllStudents,
  deleteStudent,
  resetTardyCounts,
  updateStudent,
  uploadStudents,
} from './students.slice'

import { deleteAllTardies, addTardy, deleteTardy, fetchTardiesForStudent } from './tardies.slice'
import {
  acceptInvite,
  createUser,
  fetchUsers,
  inviteUser,
  revokeAccess,
  updateUser,
} from './users.slice'

const notificationsAdapter = createEntityAdapter()

const initialState = notificationsAdapter.getInitialState({
  error: null,
  success: null,
  warning: null,
  info: null,
})

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotifications: state => {
      state.error = null
      state.success = null
      state.info = null
      state.warning = null
    },
    setError: (state, action) => {
      state.success = null
      state.info = null
      state.warning = null
      state.error = action.payload
    },
    setSuccess: (state, action) => {
      state.error = null
      state.warning = null
      state.info = null
      state.success = action.payload
    },
    setWarning: (state, action) => {
      state.error = null
      state.warning = action.payload
      state.info = null
      state.success = null
    },
    setInfo: (state, action) => {
      state.error = null
      state.warning = null
      state.info = action.payload
      state.success = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addDetention.fulfilled, (state, action) => {
        state.success = null
        state.info = null
        state.warning = action.payload.message
        state.error = null
      })
      .addCase(addOfficeVisit.fulfilled, (state, action) => {
        state.success = null
        state.info = null
        state.warning = null
        state.error = action.payload.message
      })
      // set success on fulfilled
      .addMatcher(
        isAnyOf(
          updateAddress.fulfilled,
          updateClassPeriods.fulfilled,
          updateConsequences.fulfilled,
          // logOut.fulfilled,
          logIn.fulfilled,
          signUp.fulfilled,
          printTestPage.fulfilled,
          // addTardy.fulfilled,
          deleteAllStudents.fulfilled,
          deleteAllTardies.fulfilled,
          resetTardyCounts.fulfilled,
          deleteAllDetentions.fulfilled,
          deleteAllOfficeVisits.fulfilled,
          deleteTardy.fulfilled,
          deleteDetention.fulfilled,
          deleteOfficeVisit.fulfilled,
          completeDetention.fulfilled,
          completeOfficeVisit.fulfilled,
          updateStudent.fulfilled,
          deleteStudent.fulfilled,
          // fetchTardiesForStudent.fulfilled,
          updateConsequences.fulfilled,
          createUser.fulfilled,
          inviteUser.fulfilled,
          acceptInvite.fulfilled,
          revokeAccess.fulfilled,
          uploadStudents.fulfilled,
          changePassword.fulfilled,
          resetPassword.fulfilled,
          updateMessaging.fulfilled,
          addTardy.fulfilled,
          updateUser.fulfilled
        ),
        (state, action) => {
          state.error = null
          state.warning = null
          state.info = null
          state.success = action.payload.message
        }
      )
      // set error on rejections
      .addMatcher(
        isAnyOf(
          // fetchStudents.rejected,
          // fetchPrinters.rejected,
          // fetchSettings.rejected,
          fetchUsers.rejected,
          updateAddress.rejected,
          changePassword.rejected,
          resetPassword.rejected,
          // logOut.rejected,
          logIn.rejected,
          signUp.rejected,
          printTestPage.rejected,
          // addTardy.rejected,
          fetchHistory.rejected,
          deleteAllStudents.rejected,
          deleteAllTardies.rejected,
          resetTardyCounts.rejected,
          deleteAllDetentions.rejected,
          deleteAllOfficeVisits.rejected,
          deleteTardy.rejected,
          deleteDetention.rejected,
          deleteOfficeVisit.rejected,
          completeDetention.rejected,
          completeOfficeVisit.rejected,
          updateStudent.rejected,
          deleteStudent.rejected,
          fetchTardiesForStudent.rejected,
          updateConsequences.rejected,
          createUser.rejected,
          inviteUser.rejected,
          acceptInvite.rejected,
          revokeAccess.rejected,
          uploadStudents.rejected,
          resetPassword.rejected,
          updateMessaging.rejected,
          addTardy.rejected,
          addDetention.rejected,
          addOfficeVisit.rejected,
          updateUser.rejected
        ),
        (state, action) => {
          state.error = action?.payload
          state.warning = null
          state.info = null
          state.success = null
        }
      )
      // reset all messages on pending
      .addMatcher(
        isAnyOf(
          updateAddress.pending,
          // fetchPrinters.pending,
          // fetchSettings.pending,
          // fetchUsers.pending,
          changePassword.pending,
          resetPassword.pending,
          // logOut.pending,
          logIn.pending,
          signUp.pending,
          printTestPage.pending,
          deleteAllStudents.pending,
          deleteAllTardies.pending,
          resetTardyCounts.pending,
          deleteAllDetentions.pending,
          deleteAllOfficeVisits.pending,
          deleteTardy.pending,
          deleteOfficeVisit.pending,
          deleteDetention.pending,
          completeDetention.pending,
          completeOfficeVisit.pending,
          updateStudent.pending,
          deleteStudent.pending,
          // fetchTardiesForStudent.pending,
          updateConsequences.pending,
          createUser.pending,
          inviteUser.pending,
          acceptInvite.pending,
          revokeAccess.pending,
          uploadStudents.pending,
          updateMessaging.pending,
          addTardy.pending,
          addTardy.pending,
          addDetention.pending,
          addOfficeVisit.pending
        ),
        (state, action) => {
          state.error = null
          state.success = null
          state.info = null
          state.warning = null
        }
      )
      // these actions have no message
      .addMatcher(
        isAnyOf(
          // fetchStudents.fulfilled,
          fetchPrinters.fulfilled,
          // fetchSettings.fulfilled,
          fetchUsers.fulfilled,
          createUser.fulfilled
        ),
        (state, action) => {
          state.error = null
        }
      )
  },
})
export const {
  clearNotifications,
  setError,
  setSuccess,
  setWarning,
  setInfo,
} = notificationsSlice.actions

export default notificationsSlice.reducer

export const getErrorMsg = state => state.notifications.error
export const getSuccessMsg = state => state.notifications.success
export const getInfoMsg = state => state.notifications.info
export const getWarningMsg = state => state.notifications.warning
