import {
  Container,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { ScanProcessor } from '.'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchTerm, getSearchTerm } from '../../../store/students.slice'
import { compose } from 'redux'
import { withFirebase } from '../../higher-order/Firebase'
import SearchIcon from '@material-ui/icons/Search'
import { setError } from '../../../store/notifications.slice'
import React from 'react'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const Scanner = props => {
  const barcode = useSelector(getSearchTerm)
  const classes = useStyles()
  // const autoPrint = useSelector(getShouldAutoPrint)

  const dispatch = useDispatch()

  const handleError = event => {
    // TODO: figure out why event is ""
    dispatch(setError(event.error))
  }

  const handleScan = barcode => {
    dispatch(setSearchTerm(barcode))
  }

  const handleSearch = e => {
    dispatch(setSearchTerm(e.target.value))
  }

  // const toggleAutoPrint = () => {
  //   dispatch(setAutoPrint(!autoPrint))
  // }

  return (
    <Container className={classes.paper} component='main' maxWidth='sm'>
      <Typography component='h1' variant='h3'>
        Scan or Search
      </Typography>
      <div>(Badge Id, First Name, Last Name)</div>
      <Grid container spacing={3} justify='center' alignContent='center'>
        <ScanProcessor
          onError={evt => {
            handleError(evt)
          }}
          onScan={code => {
            handleScan(code)
          }}
        />
        <Grid item xs={12}>
          <TextField
            className={classes.margin}
            fullWidth
            variant='outlined'
            autoFocus
            onChange={handleSearch}
            value={barcode}
            id='barcode-search'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
      </Grid>
      {/* <Grid container spacing={1} justify='space-evenly'>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={autoPrint || false}
                onChange={toggleAutoPrint}
                name='autoPrintSwitch'
              />
            }
            label='Auto Add Tardy'
          />
        </FormGroup>
      </Grid> */}
    </Container>
  )
}

export default compose(withFirebase)(Scanner)
