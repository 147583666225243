import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allUsers, createUser, updateUser, usersLoading } from '../../../store/users.slice'
import SendIcon from '@material-ui/icons/Send'
import BlockIcon from '@material-ui/icons/Block'
import { UserInviteModal } from '../Modals'
import { getAccountId, getAuthUser } from '../../../store/session.slice'
import compose from 'recompose/compose'
import { withFirebase } from '../../higher-order/Firebase'
import { withAuthorization } from '../../higher-order/Session'
import { setError } from '../../../store/notifications.slice'
import RefreshIcon from '@material-ui/icons/Refresh'
import UserRevokeAccessModal from '../Modals/userRevokeAccessModal'
import { isAdmin } from '../../../helpers'
// Rules:
//
// Station CANNOT see this
// Admin CANNOT change or delete other admins (once they have accepted invitation)
// Admin CAN create/invite other admins/stations
// AccountOwner CAN do all of those things
// AccountOwner CAN transfer AccountOwner role to another Admin

const UserGrid = props => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false)
  const [revokeAccessModalIsOpen, setRevokeAccessModalIsOpen] = useState(false)
  const authUser = useSelector(getAuthUser)
  const loading = useSelector(usersLoading)
  const accountId = useSelector(getAccountId)

  const users = useSelector(allUsers)
  const tableRef = React.useRef()
  const dispatch = useDispatch()

  const handleCreateUser = user => {
    dispatch(createUser({ firebase: props.firebase, user }))
  }

  const handleUpdateUser = user => {
    dispatch(updateUser({ firebase: props.firebase, user }))
  }

  const handleOpenInviteModal = user => {
    setSelectedUser(user)
    setInviteModalIsOpen(true)
  }

  const handleCloseInviteModal = () => {
    setSelectedUser(null)
    setInviteModalIsOpen(false)
  }

  const handleOpenRevokeAccessModal = user => {
    setSelectedUser(user)
    setRevokeAccessModalIsOpen(true)
  }

  const handleCloseRevokeAccessModal = () => {
    setSelectedUser(null)
    setRevokeAccessModalIsOpen(false)
  }

  return (
    <React.Fragment>
      {users && (
        <MaterialTable
          title='Users'
          isLoading={loading}
          columns={[
            {
              title: 'Id',
              field: 'id',
              type: 'string',
              defaultSort: 'asc',
              editable: 'never',
              hidden: true,
            },
            {
              title: 'Email',
              field: 'email',
              type: 'string',
            },
            {
              title: 'Role',
              field: 'role',
              type: 'string',
              lookup: {
                STATION: 'Station User',
                ADMIN: 'Admin User',
                // ACCOUNTHOLDER: 'Account Admin User', //TODO: later we'll add account transfer
              },
            },
            {
              title: 'Invited',
              field: 'invitedOn',
              type: 'string',
              editable: 'never',
            },
            {
              title: 'Accepted',
              field: 'acceptedOn',
              type: 'string',
              editable: 'never',
            },
          ]}
          // below is a hack for material-table, since it is adding tabledata to objs in db
          data={users.map(x => Object.assign({}, x))}
          actions={[
            rowData => ({
              icon: () => {
                if (rowData.role !== 'ACCOUNTHOLDER') {
                  if (rowData.acceptedOn === undefined || rowData.acceptedOn === null) {
                    if (rowData.invitedOn === undefined || rowData.invitedOn === null) {
                      return <SendIcon style={{ color: 'green' }} />
                    }
                    return <RefreshIcon style={{ color: 'green' }} />
                  }
                }
              },
              tooltip:
                rowData.role === 'ACCOUNTHOLDER'
                  ? '-'
                  : rowData.invitedOn === undefined || rowData.invitedOn === null
                  ? 'Invite User'
                  : 'Resend Invite',
              onClick: (event, rowData) => {
                // TODO: handle rescind invite modal
                if (rowData.role !== 'ACCOUNTHOLDER') {
                  handleOpenInviteModal(rowData)
                }
              },
              hidden:
                rowData.role !== 'ACCOUNTHOLDER' &&
                rowData.acceptedOn !== undefined &&
                rowData.acceptedOn !== null,
            }),
            rowData => ({
              icon: () =>
                rowData.role !== 'ACCOUNTHOLDER' &&
                rowData.acceptedOn !== undefined &&
                rowData.acceptedOn !== null ? (
                  <BlockIcon />
                ) : null,
              tooltip: 'Revoke Access',
              onClick: (event, rowData) => {
                // admins may revoke access of stations
                // accountholders may revoke access of admins
                if (
                  authUser.role === 'ACCOUNTHOLDER' ||
                  rowData.role === 'STATION' ||
                  authUser.role === 'ADMIN'
                )
                  handleOpenRevokeAccessModal(rowData)
                else dispatch(setError('Insufficient authorization to revoke access'))
              },
              hidden:
                rowData.role === 'ACCOUNTHOLDER' ||
                rowData.acceptedOn === undefined ||
                rowData.acceptedOn === null,
            }),
          ]}
          editable={{
            onRowAdd:
              authUser.role === 'ACCOUNTHOLDER' || authUser.role === 'ADMIN'
                ? newData =>
                    new Promise((resolve, reject) => {
                      if (newData.role === 'ACCOUNTHOLDER') {
                        // TODO: implement passing of account ownership
                        const message = 'cannot create accountholder users'
                        dispatch(setError(message))
                        reject(message)
                      }
                      newData.accountId = accountId
                      handleCreateUser(newData)
                      resolve()
                    })
                : null,
            onRowUpdate:
              authUser.role === 'ACCOUNTHOLDER' || authUser.role === 'ADMIN'
                ? (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        if (authUser.role === 'ADMIN') {
                          if (oldData.role === 'ADMIN' && oldData.acceptedOn != null) {
                            const message =
                              'cannot edit admins who have already accepted invitation'
                            dispatch(setError(message))
                            reject(message)
                          } else if (oldData.role === 'ACCOUNTHOLDER') {
                            const message = 'cannot edit accountholder user'
                            dispatch(setError(message))
                            reject(message)
                          } else if (newData.role === 'ACCOUNTHOLDER') {
                            // TODO: implement passing of account ownership
                            const message = 'cannot create accountholder user'
                            dispatch(setError(message))
                            reject(message)
                          }
                        }
                        // else we good
                        else if (authUser.role === 'ACCOUNTHOLDER') {
                          // const dataUpdate = { ...oldData }
                          // const index = oldData.tableData.id
                          // dataUpdate[index] = newData
                          handleUpdateUser(newData)
                        }
                        resolve()
                      }, 1000)
                    })
                : null,
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                debugger
                setTimeout(() => {
                  if (oldData.role === 'ACCOUNTHOLDER') {
                    const message = 'cannot delete accountholder users'
                    dispatch(setError(message))
                    reject(message)
                  }
                  // const dataDelete = [...data]
                  // const index = oldData.tableData.id
                  // dataDelete.splice(index, 1)
                  // handleSetData([dataDelete])

                  resolve()
                }, 1000)
              }),
          }}
          options={{
            paging: true,
            pageSize: 10,
            paginationType: 'normal',
            searchFieldVariant: 'outlined',
            search: true,
            searchAutoFocus: true,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'SSS-TardyApp-Users-' + Date().toLocaleLowerCase(),
            dateSetting: 'en-US',
            tableLayout: 'auto',
          }}
          tableRef={tableRef}
          // onRowClick={(event, rowData) => handleOpenDetails(rowData)}
        />
      )}
      {revokeAccessModalIsOpen && (
        <UserRevokeAccessModal
          open={revokeAccessModalIsOpen}
          user={{ ...selectedUser }}
          handleClose={handleCloseRevokeAccessModal}
        />
      )}
      {inviteModalIsOpen && (
        <UserInviteModal
          user={selectedUser}
          open={inviteModalIsOpen}
          handleClose={handleCloseInviteModal}
        />
      )}
    </React.Fragment>
  )
}

const condition = authUser => isAdmin(authUser)

export default compose(withFirebase, withAuthorization(condition))(UserGrid)
