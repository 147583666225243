import MaterialTable from 'material-table'
import React from 'react'
import { Container } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getSettingsWorking } from '../../../store/account.slice'

const HistoryGrid = props => {
  const records = props.records
  const loading = useSelector(getSettingsWorking)
  const tableRef = React.useRef()

  const handleSearchChange = event => {
    var count = tableRef.current.state.data.length

    if (count === 1) {
      props.onAction(tableRef.current.state.data[0])
    }
  }

  return (
    <Container component='main' maxWidth='lg'>
      {records && (
        <MaterialTable
          title='Account History'
          isLoading={loading}
          columns={[
            {
              title: 'TimestampSort',
              field: 'timestamp',
              type: 'string',
              defaultSort: 'desc',
              hidden: true,
            },
            {
              title: 'Timestamp',
              field: 'timestampDisplay',
              type: 'datetime',
              defaultSort: 'desc',
            },
            {
              title: 'Event',
              field: 'message',
              type: 'string',
            },
          ]}
          // below is a hack for material-table, since it is adding tabledata to objs in db
          data={records.map(x => Object.assign({}, x))}
          // actions={[
          //   {
          //     iconProps: { style: { color: 'orange' } },
          //     icon: props.actionIcon,
          //     tooltip: props.actionLabel,
          //     onClick: (event, rowData) => {
          //       props.onAction(rowData)
          //     },
          //   },
          // ]}
          options={{
            paging: true,
            pageSize: 10,
            paginationType: 'normal',
            searchFieldVariant: 'outlined',
            search: true,
            searchAutoFocus: true,
            // searchFieldAlignment: 'center',
            exportButton: true,
            exportAllData: true,
            exportFileName: 'SSS-TardyApp-AppHistory-' + Date().toLocaleLowerCase(),
            dateSetting: 'en-US',
          }}
          tableRef={tableRef}
          // onRowClick={(event, rowData) => props.onAction(rowData)}
          onSearchChange={handleSearchChange}
        />
      )}
    </Container>
  )
}

export default HistoryGrid
