import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import compose from 'recompose/compose'
import { getAuthUser } from '../../../store/session.slice'
import { allStudents, getStudentsLoading } from '../../../store/students.slice'
import { withAuthorization } from '../../higher-order/Session'
import { TardyAddModal } from '../Modals'

const StudentGrid = () => {
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  // const [addModalIsOpen, setAddModalIsOpen] = useState(false)
  const students = useSelector(allStudents)
  const loading = useSelector(getStudentsLoading)
  const authUser = useSelector(getAuthUser)
  const tableRef = React.useRef()

  const handleSearchChange = event => {
    var count = tableRef.current.state.data.length

    if (count === 1) {
      handleOpenDetails(tableRef.current.state.data[0])
    }
  }

  const handleOpenDetails = student => {
    setSelectedStudent(student)
    setModalIsOpen(true)
  }

  const handleModalClose = () => {
    setModalIsOpen(false)
  }

  return (
    <React.Fragment>
      {students && (
        <MaterialTable
          title='Students'
          isLoading={loading}
          columns={[
            {
              title: 'First Name',
              field: 'firstName',
              type: 'string',
            },
            {
              title: 'Last Name',
              field: 'lastName',
              type: 'string',
            },
            {
              title: 'Badge Id',
              field: 'badgeId',
              type: 'string',
              defaultSort: 'asc',
            },
            {
              title: 'Grade Level',
              field: 'gradeLevel',
              type: 'string',
              // hiddenByColumnsButton: true,
              // removable: true,
              emptyValue: 'n/a',
            },
          ]}
          // below is a hack for material-table, since it is adding tabledata to objs in db
          data={students.map(x => Object.assign({}, x))}
          actions={[
            {
              iconProps: { style: { color: 'orange' } },
              icon: 'launch',
              tooltip: 'Open',
              onClick: (event, rowData) => {
                handleOpenDetails(rowData)
              },
            },
            // {
            //   icon: 'add',
            //   tooltip: 'Add Student',
            //   isFreeAction: true,
            //   onClick: () => handleOpenDetails(),
            // },
          ]}
          options={{
            paging: true,
            pageSize: 10,
            paginationType: 'normal',
            searchFieldVariant: 'outlined',
            search: true,
            searchAutoFocus: true,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'SSS-TardyApp-StudentRecords-' + Date().toLocaleLowerCase(),
            dateSetting: 'en-US',
            // columnsButton: true,
            tableLayout: 'auto',
          }}
          tableRef={tableRef}
          onRowClick={(event, rowData) => handleOpenDetails(rowData)}
          onSearchChange={handleSearchChange}
        />
      )}
      {modalIsOpen && (
        <TardyAddModal
          open={modalIsOpen}
          readonly={true}
          student={{ ...selectedStudent }}
          authUser={authUser}
          handleClose={handleModalClose}
        />
      )}
    </React.Fragment>
  )
}

const condition = authUser => authUser

export default compose(withAuthorization(condition))(StudentGrid)
