const defaultTardyMessageTitle = `YOU ARE TARDY`
const defaultTardyMessageDescription = ''
const defaultDetentionMessageTitle = `DETENTION ASSIGNED: EXCESSIVE TARDIES`
const defaultDetentionMessageDescription = ``
const defaultOfficeReferralMessageTitle = `DO NOT GO TO CLASS`
const defaultOfficeReferralMessageDescription = `GO DIRECTLY TO: PRINCIPAL'S OFFICE`

export {
  defaultTardyMessageTitle,
  defaultTardyMessageDescription,
  defaultDetentionMessageTitle,
  defaultDetentionMessageDescription,
  defaultOfficeReferralMessageTitle,
  defaultOfficeReferralMessageDescription,
}
