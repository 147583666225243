import MaterialTable from 'material-table'
import React from 'react'
import { useSelector } from 'react-redux'
import compose from 'recompose/compose'
import { getDetentionsAdding } from '../../../store/detentions.slice'
import { getOfficeVisitsAdding } from '../../../store/officeVisits.slice'
import { getTardiesAdding, getTardiesLoading } from '../../../store/tardies.slice'

import { withFirebase } from '../../higher-order/Firebase'
import { withAuthorization } from '../../higher-order/Session'

const StudentTardyHistoryGrid = props => {
  const loading = useSelector(getTardiesLoading)
  const addingTardy = useSelector(getTardiesAdding)
  const addingDetention = useSelector(getDetentionsAdding)
  const addingOfffice = useSelector(getOfficeVisitsAdding)

  return (
    <React.Fragment>
      {props.tardies && (
        <MaterialTable
          title='Tardy History'
          isLoading={loading || addingTardy || addingDetention || addingOfffice}
          columns={[
            {
              title: 'DateSort',
              field: 'timestamp',
              type: 'datetime',
              defaultSort: 'desc',
              hidden: true,
            },
            {
              title: 'Date',
              field: 'timestampDisplay',
              type: 'string',
              defaultSort: 'desc',
            },
            {
              title: 'Period',
              field: 'period',
              type: 'string',
            },
            {
              title: 'Result',
              field: 'consequence',
              type: 'string',
            },
          ]}
          // below is a hack for material-table, since it is adding tabledata to objs in db
          data={props.tardies.map(x => Object.assign({}, x))}
          // actions={[
          //   {
          //     iconProps: { style: { color: 'orange' } },
          //     icon: 'launch',
          //     tooltip: 'Open',
          //     // onClick: (event, rowData) => {
          //     //   handleOpenDetails(rowData)
          //     // },
          //   },
          // {
          //   icon: 'add',
          //   tooltip: 'Add Student',
          //   isFreeAction: true,
          //   // onClick: () => handleOpenDetails(),
          // },
          // ]}
          options={{
            rowStyle: {
              fontSize: 12,
            },
            padding: 'dense',
            paging: true,
            pageSize: 5,
            paginationType: 'normal',
            searchFieldVariant: 'outlined',
            search: false,
            searchAutoFocus: true,
            tableLayout: 'auto',
            // searchFieldAlignment: 'center',
          }}
          // tableRef={tableRef}
          // onRowClick={(event, rowData) => handleOpenDetails(rowData)}
          // onSearchChange={handleSearchChange}
        />
      )}
    </React.Fragment>
  )
}
const condition = authUser => authUser

export default compose(withFirebase, withAuthorization(condition))(StudentTardyHistoryGrid)
