import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/performance'
import * as ROLES from '../../../constants/roles'
import {
  getDefaultConsequences,
  getDefaultMessaging,
  getDefaultPeriods,
  ToDateTime,
} from '../../../helpers'
import { BASE_URL } from '../../../constants/api'

// DEV account
// const config = {
//   apiKey: 'AIzaSyCp2M5iNuQfDwuD5cjKWKVGiDY83q45uFo',
//   authDomain: 'sssolutions-2f242.firebaseapp.com',
//   projectId: 'sssolutions-2f242',
//   storageBucket: 'sssolutions-2f242.appspot.com',
//   messagingSenderId: '501729542238',
//   appId: '1:501729542238:web:cc4e1590da593f398274cf',
//   measurementId: 'G-FX3BK0619M',
//   databaseUrl: 'https://sssolutions-2f242-default-rtdb.firebaseio.com/',
// }

const config = {
  apiKey: 'AIzaSyDophDV8NHbcP6Dq5aO8WE8MTW14Shf0ZQ',
  authDomain: 'tardy-app-cab91.firebaseapp.com',
  projectId: 'tardy-app-cab91',
  storageBucket: 'tardy-app-cab91.appspot.com',
  messagingSenderId: '389590908844',
  appId: '1:389590908844:web:e64be0414fd19242490409',
  measurementId: 'G-857ZNE6ZC9',
}
class Firebase {
  constructor() {
    app.initializeApp(config)

    app.performance()

    this.auth = app.auth()
    this.db = app.firestore()
    this.rtdb = app.database()
    this.storage = app.storage()
  }

  // #region *** Collections Ref ***
  students = () => this.db.collection('students')
  student = id => this.db.collection('students').doc(id)
  tardies = () => this.db.collection('tardies')
  tardy = id => this.db.collection('tardies').doc(id)
  detentions = () => this.db.collection('detentions')
  detention = id => this.db.collection('detentions').doc(id)
  officeReferrals = () => this.db.collection('officeVisits')
  officeReferral = id => this.db.collection('officeVisits').doc(id)
  historyEvents = () => this.db.collection('events')
  historyEvent = id => this.db.collection('events').doc(id)
  users = () => this.db.collection('users')
  user = id => this.db.collection('users').doc(id)
  accounts = () => this.db.collection('accounts')
  account = id => this.db.collection('accounts').doc(id)
  accountMessaging = id => this.db.collection('accounts').doc(id).collection('messaging')
  // #endregion

  // #region *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password, accountId) =>
    this.auth.createUserWithEmailAndPassword(email, password)
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)
  doSignOut = () => this.auth.signOut()
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)
  //#endregion

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  // #region *** Merge Auth and DB User API ***
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        await this.sleep(1000)
        const dbUser = await this.users()
          .where('authId', '==', authUser.uid)
          .get()
          .then(snapshot => {
            return snapshot.docs[0]?.data()
          })

        if (dbUser) {
          if (!dbUser?.role) {
            dbUser.role = ROLES.STATION
          }
          // merge auth and db user
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            accountId: dbUser.accountId,
            ...dbUser,
          }
        } else {
          authUser = null
        }

        next(authUser)
      } else {
        fallback()
      }
    })
  // #endregion

  // #region *** User/Account API ***
  doCreateAccount = async name => {
    const today = new Date()
    const trialEnding = today.setDate(today.getDate() + 90)

    var account = {}
    account['name'] = name
    account['isPaidAccount'] = false
    account['trialEnding'] = trialEnding
    account['periods'] = getDefaultPeriods()
    account['consequences'] = getDefaultConsequences()
    account['address'] = {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
    }
    account['messaging'] = getDefaultMessaging()

    // should have:
    // - name
    // - default periods, consequences
    return await this.accounts()
      .add(account)
      .then(res => res.id)

    // should return account for assigning to user who created
  }
  doPatchAccount = (accountId, key, val) => {
    var accountUpdate = {}
    accountUpdate[`${key}`] = val

    // console.info('updating account...')
    // console.info(accountUpdate)

    const message = `Updated ${key} to: ${Object.values(val)}`
    const timestamp = Date.now()
    const timestampDisplay = ToDateTime(timestamp)

    return this.account(accountId)
      .update(accountUpdate)
      .then(async () => {
        this.historyEvents().add({
          accountId,
          message,
          timestamp,
          timestampDisplay,
        })
      })
      .then(() => {
        return 'Update Successful'
      })
      .catch(error => {
        console.error('error updating account...', error)
      })
  }
  doGetAccountHistory = async accountId => {
    return await this.historyEvents()
      .where('accountId', '==', accountId)
      .get()
      .then(querySnapshot => {
        var results = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          const id = doc.id
          results.push({ id, ...data })
        })

        return results
      })
  }
  doCreateUser = async user => {
    return await this.users()
      .add(user)
      .then(res => {
        return res.id
      })
  }
  doUpsertUser = async user => {
    await this.user(user.id).set(user)

    return user
  }
  doInviteUser = async (accountId, accountName, user) => {
    const updatedUser = { ...user }
    const encodedAccountName = encodeURIComponent(accountName)

    const link = `${BASE_URL}/#/accounts/${accountId}/name/${encodedAccountName}/invite/${user.id}`
    console.log('link: ', link)
    const message = await this.db
      .collection('mail')
      .add({
        to: user.email,
        from: 'bryan@simpleschoolsolutions.org',
        // message: {
        //   text: `Please click link to accept invitation. If you do not know ${accountName}, please disregard. Link: ${link}`,
        // },
        template: {
          name: 'InviteUser',
          data: {
            schoolname: `${accountName}`,
            link: `${link}`,
          },
        },
      })
      .then(() => 'Queued email for delivery!')

    const historyMessage = `${user.email} invited`
    const timestamp = Date.now()
    const timestampDisplay = ToDateTime(timestamp)

    await this.historyEvents().add({
      accountId,
      historyMessage,
      timestamp,
      timestampDisplay,
    })

    updatedUser.invitedOn = ToDateTime(Date.now())
    updatedUser.accountId = accountId

    await this.users().doc(user.id).set(updatedUser)
    return { user: updatedUser, message }
  }
  doAcceptUserInvite = async (id, email, pass, accountId) => {
    // TODO: Is this the same account?
    // For now we'll assume if they were invited by some account,
    // that it is the account which invited them
    return await this.doCreateUserWithEmailAndPassword(email, pass).then(async authUser => {
      const message = `${email} successfully signed up!`
      const timestamp = Date.now()
      const timestampDisplay = ToDateTime(timestamp)

      var userUpdate = {}
      userUpdate[`authId`] = authUser.user.uid
      userUpdate[`acceptedOn`] = timestampDisplay
      userUpdate[`email`] = email
      const response = {}

      response['message'] = message
      response['user'] = authUser

      await this.user(id)
        .update(userUpdate)
        .then(async () => {
          await this.historyEvents().add({
            accountId,
            message,
            timestamp,
            timestampDisplay,
          })
        })
      await this.user(id)
        .get()
        .then(res => {
          response['user'] = res.data()
        })

      return response
    })
  }
  doRevokeUserAccess = async (userId, accountId, performedByUserId) => {
    // remove accountId from user
    var userUpdate = {}
    userUpdate[`acceptedOn`] = null
    await this.user(userId).update(userUpdate)

    const accountName = await this.account(accountId)
      .get()
      .then(snapshot => {
        return snapshot.data().name
      })

    const user = await this.user(userId)
      .get()
      .then(snapshot => {
        return snapshot.data()
      })

    // email user being removed with notification
    await this.db.collection('mail').add({
      to: user.email,
      from: 'weaverb9@gmail.com',
      message: {
        subject: `You've been removed from ${accountName}'s tardy app`,
        text: `If you believe this has been done in error, please contact ${accountName} at {some email link}.`,
        html: `If you believe this has been done in error, please contact ${accountName} at {some email link}.`,
      },
    })

    return { user: user, message: "User's access has been revoked." }
    // do nothing to users auth
  }

  // *** Students API ***
  checkIfStudentExists = async (student, accountId) => {
    //no duplicate ids allowed for an account
    return await this.students()
      // .where('firstName', '==', student.firstName)
      // .where('lastName', '==', student.lastName)
      .where('badgeId', '==', student.badgeId)
      // .where('email', '==', student?.email)
      .where('accountId', '==', accountId)
      .get()
  }

  doUpsertStudent = async student => {
    var existingStudent = await this.student(student.id).get()
    if (existingStudent.exists) {
      const withObject = {}
      withObject['firstName'] = student.firstName
      withObject['lastName'] = student.lastName
      student?.email != null ? (withObject['email'] = student.email) : (withObject['email'] = null)
      student?.street1 != null
        ? (withObject['street1'] = student.street1)
        : (withObject['street1'] = null)
      student?.city != null ? (withObject['city'] = student.city) : (withObject['city'] = null)
      student?.state != null ? (withObject['state'] = student.state) : (withObject['state'] = null)
      student?.zipCode != null
        ? (withObject['zipCode'] = student.zipCode)
        : (withObject['zipCode'] = null)
      await this.student(student.id).update(withObject)
      return await this.student(student.id)
        .get()
        .then(res => res.data())
    } else {
      const newStudentRef = await (await this.students().add(student)).get()
      const newStudent = newStudentRef.data()
      newStudent.id = newStudentRef.id
      return newStudent
    }
  }
  doFetchStudents = async accountId => {
    return await this.students()
      .where('accountId', '==', accountId)
      .get()
      .then(querySnapshot => {
        var results = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          const id = doc.id
          results.push({ id, ...data })
        })

        return results
      })
  }

  // doFetchStudentById = async (id, accountId) => {
  //   return await this.students()
  //     .where('badgeId', '==', id)
  //     .where('accountId', '==', accountId)
  //     .get()
  //     .then(querySnapshot => {
  //       return querySnapshot.docs[0]?.data()
  //     })
  // }

  doDownloadRequiredFieldsStudentTemplate = () => {
    return this.doDownloadStudentTemplate('student_template_required_fields_v1.csv')
  }
  doDownloadAllFieldsStudentTemplate = () => {
    return this.doDownloadStudentTemplate('student_template_all_fields_v3.csv')
  }
  doDownloadStudentTemplate = templateName => {
    var storageRef = this.storage.ref()

    return storageRef
      .child(templateName)
      .getDownloadURL()
      .then(url => {
        // This can be downloaded directly:
        var xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = event => {
          var a = document.createElement('a')
          a.href = window.URL.createObjectURL(xhr.response)
          a.download = templateName
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          return xhr.response
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch(error => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break
          case 'storage/canceled':
            // User canceled the upload
            break
          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break
          default:
            break
        }
      })
  }
  doUploadStudent = async (student, accountId) => {
    const message = `uploaded student: ${student.firstName} ${student.lastName} (${student.badgeId})}`
    const timestamp = Date.now()
    const timestampDisplay = ToDateTime(timestamp)

    var studentToCreate = { ...student }
    studentToCreate.createdOn = timestampDisplay

    await this.students()
      .add(studentToCreate)
      .then(() => {
        this.historyEvents().add({
          accountId,
          message,
          timestamp,
          timestampDisplay,
        })
      })
      .catch(err => {
        console.error('error adding student: ', err)
      })

    return {
      student: studentToCreate,
      message: `uploaded ${student.firstName} ${student.lastName} (${student.badgeId})`,
    }
  }
  doUploadStudents = async (students, accountId) => {
    const studentsCreated = []
    for await (var student of students) {
      const message = `uploaded student: ${student.firstName} ${student.lastName} (${student.badgeId})}`
      const timestamp = Date.now()
      const timestampDisplay = ToDateTime(timestamp)

      var studentToCreate = { ...student }
      studentToCreate.createdOn = timestampDisplay

      await this.students()
        .add(studentToCreate)
        .then(() => {
          this.historyEvents().add({
            accountId,
            message,
            timestamp,
            timestampDisplay,
          })
        })
        .catch(err => {
          console.error('error adding student: ', err)
        })
      studentsCreated.push(studentToCreate)
    }

    return { students: studentsCreated, message: `${students.length} students uploaded` }
  }
  doDeleteStudent = async student => {
    const message = `deleted student: ${student.firstName} ${student.lastName} (${student.badgeId}`
    const timestamp = Date.now()
    const timestampDisplay = ToDateTime(timestamp)

    await this.student(student.id).delete()
    await this.historyEvents().add({
      accountId: student.accountId,
      message,
      timestamp,
      timestampDisplay,
    })
    return {
      id: student.badgeId,
      message: `${student.firstName} ${student.lastName} was deleted`,
    }
  }
  doDeleteAllStudents = async () => {
    const accountId = JSON.parse(localStorage.authUser).accountId
    return await this.students()
      .where('accountId', '==', accountId)
      .get()
      .then(async querySnapshot => {
        for await (var doc of querySnapshot.docs) {
          doc.ref.delete()
          await new Promise(resolve => setTimeout(resolve, 100))
        }
        const message = `deleted ${querySnapshot.docs.length} students`
        const timestamp = Date.now()
        const timestampDisplay = ToDateTime(timestamp)

        await this.historyEvents().add({
          accountId,
          message,
          timestamp,
          timestampDisplay,
        })
        return { message }
      })
  }
  // #endregion

  // #region *** Tardies API ***
  doGetTardies = async accountId => {
    return await this.tardies()
      .where('accountId', '==', accountId)
      .get()
      .then(snapshot => {
        var results = []
        snapshot.forEach(doc => {
          const data = doc.data()
          const id = doc.id
          results.push({ id, ...data })
        })

        return results
      })
  }
  doGetTardyCountForStudent = async (badgeId, accountId) => {
    return await this.tardies()
      .where('badgeId', '==', badgeId)
      .where('accountId', '==', accountId)
      .get()
      .then(snapshot => {
        return snapshot.size
      })
  }
  doGetTardiesForStudent = async (badgeId, accountId) => {
    return await this.tardies()
      .where('badgeId', '==', badgeId)
      .where('accountId', '==', accountId)
      .orderBy('timestamp', 'desc')
      .get()
      .then(async snapshot => {
        var results = []
        snapshot.forEach(doc => {
          const data = doc.data()
          const id = doc.id
          results.push({ id, ...data })
        })
        return results
      })
  }
  doAddTardy = async (period, authUser, student, consequence) => {
    const timestamp = Date.now()
    const timestampDisplay = ToDateTime(timestamp)

    const existingTardyCount = await this.tardies()
      .where('badgeId', '==', student.badgeId)
      .where('accountId', '==', student.accountId)
      .get()
      .then(snapshot => snapshot.docs.length)

    const newTardyCount = (existingTardyCount + 1).toString()

    var tardy = {
      accountId: student.accountId,
      badgeId: student.badgeId,
      studentFirstName: student.firstName,
      studentLastName: student.lastName,
      timestamp: timestamp,
      timestampDisplay: timestampDisplay,
      createdBy: authUser?.email,
      period: period,
      consequence: consequence,
      consequenceId: null,
      tardyCount: newTardyCount,
    }

    student.tardyCount = newTardyCount

    const tardyRecord = await this.tardies().add(tardy)
    tardy['id'] = tardyRecord.id

    await this.student(student.id).set(student)

    if (consequence !== 'detention' && consequence !== 'office') {
      const message = `${student.firstName} ${student.lastName} (${student.badgeId}): + 1 tardy`
      this.historyEvents().add({
        accountId: student.accountId,
        createdBy: authUser?.email,
        message,
        timestamp,
        timestampDisplay,
      })
    }

    return { tardy, student: student }
  }
  doDeleteTardy = async (id, authUser) => {
    var tardy = await (await this.tardy(id).get()).data()

    // delete tardy
    this.tardy(id).delete()

    // if consequence, delete that too
    if (tardy.consequence) {
      if (tardy.consequence === 'detention') {
        this.detention(tardy.consequenceId).delete()
      } else if (tardy.consequence === 'office') {
        this.officeReferral(tardy.consequenceId).delete()
      }
    }

    // update student tardycount -1
    const studentsResult = await this.students()
      .where('badgeId', '==', tardy.badgeId)
      .where('accountId', '==', tardy.accountId)
      .get()

    const studentId = studentsResult.docs[0].id
    const updatedStudent = { ...studentsResult.docs[0].data() }

    const existingTardyCount = await this.tardies()
      .where('badgeId', '==', updatedStudent.badgeId)
      .where('accountId', '==', updatedStudent.accountId)
      .get()
      .then(snapshot => snapshot.docs.length)

    const newTardyCount = (existingTardyCount + 1).toString()
    updatedStudent.tardyCount = newTardyCount

    const timestamp = Date.now()
    const timestampDisplay = ToDateTime(timestamp)

    const message = `${updatedStudent.firstName} ${updatedStudent.lastName} (${updatedStudent.badgeId}): - 1 tardy`
    this.historyEvents().add({
      accountId: updatedStudent.accountId,
      createdBy: authUser?.email,
      message,
      timestamp,
      timestampDisplay,
    })

    await this.student(studentId).set(updatedStudent)

    return { student: updatedStudent }
  }

  doResetTardyCounts = async () => {
    const accountId = JSON.parse(localStorage.authUser).accountId

    return await this.students()
      .where('accountId', '==', accountId)
      .get()
      .then(async querySnapshot => {
        var results = []
        var studentUpdate = {}
        studentUpdate['tardyCount'] = 0

        await querySnapshot.forEach(async doc => {
          await doc.ref.update(studentUpdate).then(() => {
            const id = doc.id
            const updatedDoc = { ...doc.data() }
            updatedDoc.tardyCount = 0
            results.push({ id, ...updatedDoc })
          })
        })
        const message = `reset tardy counts for all students`
        const timestamp = Date.now()
        const timestampDisplay = ToDateTime(timestamp)

        await this.historyEvents().add({
          accountId,
          message,
          timestamp,
          timestampDisplay,
        })

        return results
      })
  }
  doDeleteAllTardies = async () => {
    const accountId = JSON.parse(localStorage.authUser).accountId
    return await this.tardies()
      .where('accountId', '==', accountId)
      .get()
      .then(async querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete()
        })
        await this.doResetTardyCounts()
        const message = `deleted ${querySnapshot.docs.length} tardies`
        const timestamp = Date.now()
        const timestampDisplay = ToDateTime(timestamp)

        await this.historyEvents().add({
          accountId,
          message,
          timestamp,
          timestampDisplay,
        })
        return { message }
      })
  }
  //#endregion

  // #region *** Detentions API ***
  doGetDetentions = async accountId => {
    return await this.detentions()
      .where('accountId', '==', accountId)
      .orderBy('completed', 'asc')
      .orderBy('timestamp', 'asc')
      .get()
      .then(snapshot => {
        var results = []
        snapshot.forEach(doc => {
          const data = doc.data()
          const id = doc.id
          results.push({ id, ...data })
        })

        return results
      })
  }
  doAddDetention = async (period, authUser, student, tardyId) => {
    const timestamp = Date.now()
    const timestampDisplay = ToDateTime(timestamp)

    var consequenceRecord
    const detention = {
      accountId: student.accountId,
      badgeId: student.badgeId,
      studentFirstName: student.firstName,
      studentLastName: student.lastName,
      createdBy: authUser?.email,
      timestamp: timestamp,
      timestampDisplay: timestampDisplay,
      period: period,
      tardyId: tardyId,
      completed: false,
      tardyCount: student.tardyCount,
    }
    consequenceRecord = await this.detentions()
      .add(detention)
      .then(res => res)

    detention.id = consequenceRecord.id

    await this.tardies().doc(tardyId).update({ consequenceId: consequenceRecord.id })
    // this.students().doc(updatedStudent.id).set(updatedStudent)

    const message = `${student.firstName} ${student.lastName} (${student.badgeId}): + 1 detention`
    this.historyEvents().add({
      accountId: student.accountId,
      createdBy: authUser?.email,
      message,
      timestamp,
      timestampDisplay,
    })

    return { detention, student }
  }
  doDeleteAllDetentions = async () => {
    const accountId = JSON.parse(localStorage.authUser).accountId
    return await this.detentions()
      .where('accountId', '==', accountId)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete()
        })
        const message = `deleted ${querySnapshot.docs.length} detentions`
        const timestamp = Date.now()
        const timestampDisplay = ToDateTime(timestamp)

        this.historyEvents().add({
          accountId,
          message,
          timestamp,
          timestampDisplay,
        })

        return { message }
      })
  }
  doCompleteDetention = async id => {
    const timestamp = Date.now()
    const timestampDisplay = ToDateTime(timestamp)

    var withObject = {}
    withObject['completed'] = true
    withObject['completedTimestamp'] = timestamp
    withObject['completedTimestampDisplay'] = timestampDisplay

    await this.detention(id).update(withObject)
    return withObject
  }
  doDeleteDetention = async id => {
    await this.detention(id).delete()

    return id
  }
  // #endregion

  // #region *** Office Referrals API ***
  doGetOfficeVisits = async accountId => {
    return await this.officeReferrals()
      .where('accountId', '==', accountId)
      .orderBy('completed', 'asc')
      .orderBy('timestamp', 'asc')
      .get()
      .then(snapshot => {
        var results = []
        snapshot.forEach(doc => {
          const data = doc.data()
          const id = doc.id
          results.push({ id, ...data })
        })

        return results
      })
  }
  doAddOfficeVisit = async (period, authUser, student, tardyId) => {
    const message = `${student.firstName} ${student.lastName} (${student.badgeId}): + 1 tardy`
    const timestamp = Date.now()

    const timestampDisplay = ToDateTime(timestamp)

    var consequenceRecord
    const officeVisit = {
      accountId: student.accountId,
      badgeId: student.badgeId,
      studentFirstName: student.firstName,
      studentLastName: student.lastName,
      createdBy: authUser?.email,
      timestamp: timestamp,
      timestampDisplay: timestampDisplay,
      period: period,
      tardyId: tardyId,
      completed: false,
      tardyCount: student.tardyCount,
    }
    consequenceRecord = await this.officeReferrals()
      .add(officeVisit)
      .then(res => res)

    officeVisit.id = consequenceRecord.id

    this.tardies().doc(tardyId).update({ consequenceId: consequenceRecord.id })
    // this.students().doc(updatedStudent.id).set(updatedStudent)

    this.historyEvents().add({
      accountId: student.accountId,
      createdBy: authUser?.email,
      message,
      timestamp,
      timestampDisplay,
    })

    return { officeVisit, student }
  }
  doDeleteAllOfficeVisits = async () => {
    const accountId = JSON.parse(localStorage.authUser).accountId
    return await this.officeReferrals()
      .where('accountId', '==', accountId)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete()
        })
        const message = `deleted ${querySnapshot.docs.length} office visits`
        const timestamp = Date.now()
        const timestampDisplay = ToDateTime(timestamp)

        this.historyEvents().add({
          accountId,
          message,
          timestamp,
          timestampDisplay,
        })

        return { message }
      })
  }
  doCompleteOfficeVisit = async id => {
    const timestamp = Date.now()
    const timestampDisplay = ToDateTime(timestamp)

    var withObject = {}
    withObject['completed'] = true
    withObject['completedTimestamp'] = timestamp
    withObject['completedTimestampDisplay'] = timestampDisplay

    await this.officeReferral(id).update(withObject)
    return withObject
  }
  doDeleteOfficeVisit = async id => {
    await this.officeReferral(id).delete()
    return id
  }
  // #endregion
}

export default Firebase
