import React from 'react'
import { emphasize, withStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Chip from '@material-ui/core/Chip'
import HomeIcon from '@material-ui/icons/Home'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useHistory } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'

const StyledBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip) // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event, val) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

export const CustomizedBreadcrumbs = () => {
  var history = useHistory()

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      <StyledBreadcrumb
        component='a'
        href='#'
        label='Add Tardy'
        icon={<HomeIcon fontSize='small' />}
        onClick={() => history.push(ROUTES.SCAN)}
      />
      <StyledBreadcrumb
        label='Account'
        deleteIcon={<ExpandMoreIcon />}
        onClick={handleClick}
        onDelete={handleClick}
      />
    </Breadcrumbs>
  )
}
