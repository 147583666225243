import {
  makeStyles,
  Container,
  Button,
  Tooltip,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  LinearProgress,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'
import { withAuthorization } from '../../higher-order/Session'

import {
  allStudents,
  deleteAllStudents,
  // getResettingTardyCounts,
  getStudentsCount,
  getStudentsDeleting,
  // resetTardyCounts,
} from '../../../store/students.slice'
import {
  deleteAllOfficeVisits,
  getOfficeVisitsCount,
  getOfficeVisitsDeleting,
} from '../../../store/officeVisits.slice'
import { getAccountId } from '../../../store/session.slice'
import { deleteAllTardies, getTardiesCount, getTardiesDeleting } from '../../../store/tardies.slice'
import {
  deleteAllDetentions,
  getDetentionsCount,
  getDetentionsDeleting,
} from '../../../store/detentions.slice'
import { withFirebase } from '../../higher-order/Firebase'
import { isAdmin } from '../../../helpers'

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
}))

const ActionView = ({ isActing, onAction, title, entity, count }) => {
  const classes = useStyles()

  return (
    <Card key={title + count} className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color='textSecondary' gutterBottom>
          {count} {entity}
        </Typography>
        {/* <Typography className={classes.pos} color='textSecondary'>
          adjective
        </Typography>
        <Typography variant='body2' component='p'>
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
          
        </Typography> */}

        {isActing && <LinearProgress />}
      </CardContent>
      <CardActions>
        <Tooltip title='BE CAREFUL - THIS CANNOT BE UNDONE!'>
          <span>
            <Button
              disabled={count < 1}
              variant='contained'
              color='primary'
              type='submit'
              className={classes.submit}
              onClick={onAction}
              fullWidth
            >
              {title}
            </Button>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

const AdminActionsForm = props => {
  const dispatch = useDispatch()
  const accountId = useSelector(getAccountId)
  const isDeletingStudents = useSelector(getStudentsDeleting)
  const isDeletingTardies = useSelector(getTardiesDeleting)
  // const isResettingTardies = useSelector(getResettingTardyCounts)
  const isDeletingDetentions = useSelector(getDetentionsDeleting)
  const isDeletingOfficeVisits = useSelector(getOfficeVisitsDeleting)
  const tardyCount = useSelector(getTardiesCount)
  const studentCount = useSelector(getStudentsCount)
  const detentionCount = useSelector(getDetentionsCount)
  const officeVisitCount = useSelector(getOfficeVisitsCount)
  const students = useSelector(allStudents)

  const handleDeleteAllStudents = () => {
    dispatch(deleteAllStudents({ firebase: props.firebase, students: students }))
  }

  // const handleResetAllTardies = () => {
  //   dispatch(resetTardyCounts({ firebase: props.firebase, accountId: accountId }))
  // }

  const handleDeleteAllTardies = () => {
    dispatch(deleteAllTardies({ firebase: props.firebase, accountId: accountId }))
  }

  const handleDeleteAllDetentions = () => {
    dispatch(deleteAllDetentions({ firebase: props.firebase, accountId: accountId }))
  }

  const handleDeleteAllOfficeVisits = () => {
    dispatch(deleteAllOfficeVisits({ firebase: props.firebase, accountId: accountId }))
  }

  return (
    <Container maxWidth='md'>
      <Grid container spacing={3}>
        <Grid item>
          <ActionView
            isActing={isDeletingStudents}
            onAction={handleDeleteAllStudents}
            title='Delete All Students'
            entity={studentCount === 1 ? 'Student' : 'Students'}
            count={studentCount}
          />
        </Grid>
        <Grid item>
          <ActionView
            isActing={isDeletingTardies}
            onAction={handleDeleteAllTardies}
            title='Delete All Tardies'
            entity={tardyCount === 1 ? 'Tardy' : 'Tardies'}
            count={tardyCount}
          />
        </Grid>
        {/* <Grid item>
          <ActionView
            isActing={isResettingTardies}
            onAction={handleResetAllTardies}
            title='Reset All Tardies'
            entity={tardyCount === 1 ? 'Tardy' : 'Tardies'}
            count={tardyCount}
          />
        </Grid> */}

        <Grid item>
          <ActionView
            isActing={isDeletingDetentions}
            onAction={handleDeleteAllDetentions}
            title='Delete All Detentions'
            entity={detentionCount === 1 ? 'Detention' : 'Detentions'}
            count={detentionCount}
          />
        </Grid>
        <Grid item>
          <ActionView
            isActing={isDeletingOfficeVisits}
            onAction={handleDeleteAllOfficeVisits}
            title='Delete All Office Visits'
            entity={officeVisitCount === 1 ? 'Office Visit' : 'Office Visits'}
            count={officeVisitCount}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

const condition = authUser => isAdmin(authUser)

export default compose(withAuthorization(condition), withFirebase)(AdminActionsForm)
