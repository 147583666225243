export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const SCAN = '/scan'
export const ACCOUNT = '/account'
export const ACCOUNT_INVITE = '/accounts/:account/name/:name/invite/:id'
export const ACCOUNT_HISTORY = '/history'
export const TARDIES = '/tardies'
export const DETENTIONS = '/detentions'
export const OFFICE_VISITS = '/office-visits'
export const ADMIN = '/admin'
export const PASSWORD_FORGET = '/pw-forget'
export const STUDENTS = '/students'
