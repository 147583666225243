import MaterialTable from 'material-table'
import compose from 'recompose/compose'
import { withFirebase } from '../../higher-order/Firebase'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import FaceIcon from '@material-ui/icons/Face'
import { Chip, createMuiTheme, MuiThemeProvider, Tooltip } from '@material-ui/core'
import { orange, red } from '@material-ui/core/colors'

const StudentUploadPreview = props => {
  const redTheme = createMuiTheme({
    palette: {
      primary: { main: red.A400 },
    },
  })
  return (
    props.students &&
    props.students.length > 0 && (
      <MaterialTable
        title='Student Upload Preview'
        columns={[
          {
            title: 'Status',
            render: rowData => {
              if (rowData.exists) {
                return (
                  <MuiThemeProvider theme={redTheme}>
                    <Tooltip title='This student already exists in the system.'>
                      <Chip
                        icon={<ErrorIcon />}
                        size='small'
                        label='Already Exists'
                        color='primary'
                        variant='outlined'
                      />
                    </Tooltip>
                  </MuiThemeProvider>
                )
              } else if (!rowData.fieldsValidated) {
                return (
                  <MuiThemeProvider theme={redTheme}>
                    <Tooltip title='One or more required fields are missing.'>
                      <Chip
                        icon={<ErrorIcon />}
                        size='small'
                        label='Missing Required Fields'
                        color='primary'
                        variant='outlined'
                      />
                    </Tooltip>
                  </MuiThemeProvider>
                )
              } else if (rowData.createdOn !== undefined) {
                return (
                  <Tooltip title='Student uploaded.'>
                    <Chip
                      icon={<FaceIcon />}
                      size='small'
                      label='Student Uploaded'
                      color='primary'
                    />
                  </Tooltip>
                )
              } else {
                return (
                  <Tooltip title='Everything looks good for upload.'>
                    <Chip
                      icon={<CheckCircleIcon />}
                      size='small'
                      label='Ready to Upload'
                      color='secondary'
                    />
                  </Tooltip>
                )
              }
            },
          },
          {
            title: 'Badge Id',
            field: 'badgeId',
            type: 'string',
            validate: rowData =>
              rowData.badgeId === ''
                ? { isValid: false, helperText: 'Badge Id cannot be empty' }
                : true,
            render: rowData => {
              if (rowData.badgeId === '') {
                return (
                  <Tooltip title='This field is required.'>
                    <ErrorIcon style={{ color: orange[500] }} />
                  </Tooltip>
                )
              } else return rowData.badgeId
            },
          },
          {
            title: 'Last Name',
            field: 'lastName',
            type: 'string',
            validate: rowData =>
              rowData.lastName === ''
                ? { isValid: false, helperText: 'Last Name cannot be empty' }
                : true,
            render: rowData => {
              if (rowData.lastName === '') {
                return (
                  <Tooltip title='This field is required.'>
                    <ErrorIcon style={{ color: orange[500] }} />
                  </Tooltip>
                )
              } else return rowData.lastName
            },
          },
          {
            title: 'First Name',
            field: 'firstName',
            type: 'string',
            validate: rowData =>
              rowData.firstName === ''
                ? { isValid: false, helperText: 'First Name cannot be empty' }
                : true,
            render: rowData => {
              if (rowData.firstName === '') {
                return (
                  <Tooltip title='This field is required.'>
                    <ErrorIcon style={{ color: orange[500] }} />
                  </Tooltip>
                )
              } else return rowData.firstName
            },
          },
          {
            title: 'Grade',
            field: 'gradeLevel',
            type: 'string',
            validate: rowData =>
              rowData.gradeLevel === ''
                ? { isValid: false, helperText: 'Grade level cannot be empty' }
                : true,
            render: rowData => {
              if (rowData.gradeLevel === '') {
                return (
                  <Tooltip title='This field is required.'>
                    <ErrorIcon style={{ color: orange[500] }} />
                  </Tooltip>
                )
              } else return rowData.gradeLevel
            },
          },
          {
            title: 'Tardy Count',
            field: 'tardyCount',
            type: 'string',
          },
        ]}
        options={{
          rowStyle: {
            fontSize: 12,
          },
          padding: 'dense',
          paging: true,
          pageSize: 10,
          paginationType: 'normal',
          searchFieldVariant: 'outlined',
          search: true,
          searchAutoFocus: false,
          tableLayout: 'fixed',
          // searchFieldAlignment: 'center',
        }}
        data={props.students.map(x => Object.assign({}, x))}
        // actions={[
        //   {
        //     icon: 'check',
        //     iconProps: { style: { color: 'orange' } },
        //     tooltip: 'Validate User',
        //     onClick: (event, rowData) => validateStudent(event, rowData),
        //   },
        // ]}
      />
    )
  )
}

export default compose(withFirebase)(StudentUploadPreview)
