import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { useSpring, animated } from 'react-spring/web.cjs' // web.cjs is required for IE 11 support
import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { compose } from '@reduxjs/toolkit'
import { withFirebase } from '../../higher-order/Firebase'
import { completeOfficeVisit, deleteOfficeVisit } from '../../../store/officeVisits.slice'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
}

const OfficeVisitEditModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleDeleteOfficeVisit = () => {
    dispatch(
      deleteOfficeVisit({
        firebase: props.firebase,
        officeVisitId: props.officeReferral.id,
        studentFirstName: props.officeReferral.studentFirstName,
        studentLastName: props.officeReferral.studentLastName,
      })
    )
    props.handleClose()
  }

  const handleCompleteOfficeVisit = () => {
    dispatch(
      completeOfficeVisit({
        firebase: props.firebase,
        officeVisitId: props.officeReferral.id,
        studentFirstName: props.officeReferral.studentFirstName,
        studentLastName: props.officeReferral.studentLastName,
      })
    )
    props.handleClose()
  }

  return (
    <div>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            {props.officeReferral.studentFirstName && props.officeReferral.studentLastName && (
              <h2 id='spring-modal-title'>
                {props.officeReferral.studentFirstName} {props.officeReferral.studentLastName}
              </h2>
            )}
            {props.officeReferral.badgeId && (
              <p id='spring-modal-description'>Badge Id: {props.officeReferral.badgeId}</p>
            )}
            {props.officeReferral.period && (
              <p id='spring-modal-description'>Period: {props.officeReferral.period}</p>
            )}
            {props.officeReferral.timestamp && (
              <p id='spring-modal-description'>Time: {props.officeReferral.timestampDisplay}</p>
            )}
            {props.officeReferral.createdBy && (
              <p id='spring-modal-description'>Created By: {props.officeReferral.createdBy}</p>
            )}
            <Button variant='contained' color='secondary' onClick={props.handleClose}>
              Close
            </Button>
            <Button variant='contained' color='primary' onClick={handleDeleteOfficeVisit}>
              Delete Office Visit
            </Button>
            <Button variant='contained' color='primary' onClick={handleCompleteOfficeVisit}>
              Complete Office Visit
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default compose(withFirebase)(OfficeVisitEditModal)
