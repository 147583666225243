import sessionReducer from './session.slice'
import studentReducer from './students.slice'
import userReducer from './users.slice'
import accountReducer from './account.slice'
import notificationsReducer from './notifications.slice'
import tardiesReducer from './tardies.slice'
import officeVisitsReducer from './officeVisits.slice'
import detentionsReducer from './detentions.slice'
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['account'],
}

const reducers = combineReducers({
  detentions: detentionsReducer,
  officeVisits: officeVisitsReducer,
  tardies: tardiesReducer,
  session: sessionReducer,
  students: studentReducer,
  users: userReducer,
  account: accountReducer,
  notifications: notificationsReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
})

export default store
