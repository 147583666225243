import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { useSpring, animated } from 'react-spring/web.cjs' // web.cjs is required for IE 11 support
import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { compose } from '@reduxjs/toolkit'
import { withFirebase } from '../../higher-order/Firebase'
import {
  completeDetention,
  deleteDetention,
} from '../../../store/detentions.slice'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
}

const DetentionEditModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleDeleteDetention = () => {
    dispatch(
      deleteDetention({
        firebase: props.firebase,
        detentionId: props.detention.id,
        studentFirstName: props.detention.studentFirstName,
        studentLastName: props.detention.studentLastName,
      })
    )
    props.handleClose()
  }

  const handleCompleteDetention = () => {
    dispatch(
      completeDetention({
        firebase: props.firebase,
        detentionId: props.detention.id,
        studentFirstName: props.detention.studentFirstName,
        studentLastName: props.detention.studentLastName,
      })
    )
    props.handleClose()
  }

  return (
    <div>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            {props.detention.studentFirstName &&
              props.detention.studentLastName && (
                <h2 id='spring-modal-title'>
                  {props.detention.studentFirstName}{' '}
                  {props.detention.studentLastName}
                </h2>
              )}
            {props.detention.badgeId && (
              <p id='spring-modal-description'>
                Badge Id: {props.detention.badgeId}
              </p>
            )}
            {props.detention.period && (
              <p id='spring-modal-description'>
                Period: {props.detention.period}
              </p>
            )}
            {props.detention.timestamp && (
              <p id='spring-modal-description'>
                Time: {props.detention.timestampDisplay}
              </p>
            )}
            {props.detention.consequence && (
              <p id='spring-modal-description'>
                Result: {props.detention.consequence}
              </p>
            )}
            {props.detention.createdBy && (
              <p id='spring-modal-description'>
                Created By: {props.detention.createdBy}
              </p>
            )}
            <Button
              variant='contained'
              color='secondary'
              onClick={props.handleClose}
            >
              Close
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleDeleteDetention}
            >
              Delete Detention
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleCompleteDetention}
            >
              Complete Detention
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default compose(withFirebase)(DetentionEditModal)
