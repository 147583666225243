import { Button } from '@material-ui/core'
import { compose } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch } from 'react-redux'
import { logOut } from '../../../store/session.slice'

import { withFirebase } from '../../higher-order/Firebase'

const SignOutButton = ({ firebase }) => {
  const dispatch = useDispatch()

  const handleSignOut = () => {
    dispatch(logOut({ firebase }))
  }

  return (
    <Button variant='contained' onClick={handleSignOut}>
      Sign Out
    </Button>
  )
}

export default compose(withFirebase)(SignOutButton)
