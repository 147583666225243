import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { useSpring, animated } from 'react-spring/web.cjs' // web.cjs is required for IE 11 support
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  addTardy,
  fetchTardiesForStudent,
  getTardiesAdding,
  getTardiesForStudent,
} from '../../../store/tardies.slice'
import { compose } from '@reduxjs/toolkit'
import { withFirebase } from '../../higher-order/Firebase'
import { getAuthUser } from '../../../store/session.slice'
import { getCurrentPeriod, getMessaging, getNextConsequence } from '../../../store/account.slice'
import { StudentTardyHistoryGrid } from '../Grids'
import Box from '@material-ui/core/Box'
import { addOfficeVisit, getOfficeVisitsAdding } from '../../../store/officeVisits.slice'
import { addDetention, getDetentionsAdding } from '../../../store/detentions.slice'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
}

const TardyAddModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const period = useSelector(getCurrentPeriod)
  const tardies = useSelector(getTardiesForStudent(props.student.badgeId, props.student.accountId))
  // const autoPrint = useSelector(getShouldAutoPrint)
  const messaging = useSelector(getMessaging)
  const nextConsequence = useSelector(getNextConsequence(tardies.length))
  const authUser = useSelector(getAuthUser)
  const addingTardy = useSelector(getTardiesAdding)
  const addingDetention = useSelector(getDetentionsAdding)
  const addingOfffice = useSelector(getOfficeVisitsAdding)

  const [addingSomething, setAddingSomething] = useState(false)

  useEffect(() => {
    dispatch(
      fetchTardiesForStudent({
        firebase: props.firebase,
        badgeId: props.student.badgeId,
        accountId: props.student.accountId,
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (addingTardy || addingOfffice || addingDetention) {
      setAddingSomething(true)
    } else {
      if (addingSomething) {
        props.handleClose()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingTardy, addingOfffice, addingDetention])

  const handleAddTardy = () => {
    switch (nextConsequence?.name) {
      case 'detention':
        dispatch(
          addDetention({
            period,
            authUser: authUser,
            student: props.student,
            firebase: props.firebase,
            consequence: nextConsequence?.name,
            msgs: messaging,
          })
        )
        break
      case 'office':
        dispatch(
          addOfficeVisit({
            period,
            authUser: authUser,
            student: props.student,
            firebase: props.firebase,
            consequence: nextConsequence?.name,
            msgs: messaging,
          })
        )
        break
      case 'tardy':
        dispatch(
          addTardy({
            period,
            authUser: authUser,
            student: props.student,
            firebase: props.firebase,
            consequence: nextConsequence?.name,
            msgs: messaging,
          })
        )
        // dispatch(setSuccess(`${props.student.firstName} ${props.student.lastName}: +1 tardy`))
        break
      default:
        break
    }
  }
  return (
    <div>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            {props.student.firstName && props.student.lastName && (
              <h1 id='spring-modal-title'>
                {props.student.firstName} {props.student.lastName}
              </h1>
            )}
            {props.student.badgeId && <h3>Badge Id: {props.student.badgeId}</h3>}
            {tardies && <h4>Total Tardies: {tardies.length}</h4>}
            {props.readonly && props.student.phoneNumber && (
              <h4>Phone #: {props.student.phoneNumber}</h4>
            )}
            {nextConsequence.name && (
              <p id='spring-modal-description'>
                {props.readonly && <span>Next tardy will result in </span>}
                {!props.readonly && <span>This action will result in: </span>}
                {nextConsequence.name === 'detention' ? (
                  <Box component='span' color='warning.main'>
                    {nextConsequence.name.toUpperCase()}
                  </Box>
                ) : nextConsequence.name === 'office' ? (
                  <Box component='span' color='error.main'>
                    {nextConsequence.name.toUpperCase()}
                  </Box>
                ) : (
                  nextConsequence.name === 'tardy' && (
                    <Box component='span' color='primary.main'>
                      {nextConsequence.name.toUpperCase()}
                    </Box>
                  )
                )}
              </p>
            )}

            <StudentTardyHistoryGrid tardies={tardies} badgeId={props.student.badgeId} />

            <Button variant='contained' color='secondary' onClick={props.handleClose}>
              Close
            </Button>
            {!props.readonly && (
              <Button variant='contained' color='primary' onClick={handleAddTardy}>
                Add Tardy
              </Button>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default compose(withFirebase)(TardyAddModal)
