import React, { useEffect } from 'react'
import { withAuthorization } from '../../components/higher-order/Session'
import { withFirebase } from '../../components/higher-order/Firebase'
import compose from 'recompose/compose'
import { Container, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHistory, getHistory } from '../../store/account.slice'
import { getAccountId } from '../../store/session.slice'
import HistoryGrid from '../../components/utility/History'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}))

const AccountHistoryPage = props => {
  const events = useSelector(getHistory)
  const accountId = useSelector(getAccountId)
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchHistory({ firebase: props.firebase, accountId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenDetails = student => {
    // setSelectedRecord(student)
    // setModalIsOpen(true)
  }

  // const handleModalClose = () => {
  //   setModalIsOpen(false)
  // }

  return (
    <Container component='main' maxWidth='lg'>
      {events && (
        <HistoryGrid
          className={classes.root}
          records={events}
          actionIcon={'launch'}
          actionLabel='Open Details'
          onAction={events => handleOpenDetails(events)}
        />
      )}
    </Container>
  )
}

const condition = authUser => authUser

export default compose(withAuthorization(condition), withFirebase)(AccountHistoryPage)
