import { Button, makeStyles } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPrinters,
  getPrinters,
  getIsFetchingPrinters,
  getSelectedPrinter,
  printTestPage,
} from '../../../store/session.slice'
import PrinterSelection from './printerSelection'

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const PrinterSettingsForm = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const isFetching = useSelector(getIsFetchingPrinters)
  const printers = useSelector(getPrinters)
  const selectedPrinter = useSelector(getSelectedPrinter)

  useEffect(() => {
    dispatch(fetchPrinters())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTestPrint = () => {
    dispatch(printTestPage())
  }

  return (
    <div>
      {isFetching && <div>Fetching...</div>}
      <Button
        variant='contained'
        color='primary'
        type='submit'
        className={classes.submit}
        onClick={handleTestPrint}
      >
        Print Test Page
      </Button>
      {selectedPrinter && (
        <h1>Selected Printer: {selectedPrinter.displayName}</h1>
      )}
      {!isFetching && printers && printers.length > 0 && (
        // <ul>
        //   {printers.map(printer => (
        //     <li key={printer.name}>{printer.name}</li>
        //   ))}
        // </ul>
        <PrinterSelection printers={printers} selected={selectedPrinter} />
      )}
      {!isFetching && printers && printers.length === 0 && (
        <div>printer settings not available on web client</div>
      )}
    </div>
  )
}

export default PrinterSettingsForm
