import Dropzone from './dropzone'
import StudentUploadPreview from './uploadPreview'
import React from 'react'
import { compose } from 'recompose'
import { withAuthorization } from '../../higher-order/Session'
import { Button, Chip, Grid, LinearProgress, makeStyles } from '@material-ui/core'
import FaceIcon from '@material-ui/icons/Face'
import {
  addInvalidUpload,
  addValidUpload,
  getAllValidatedUploads,
  getInvalidUploads,
  getIsValidatingUploads,
  getStudentsUploadComplete,
  getStudentsUploadedCount,
  getStudentsUploading,
  getTotalUploadsToValidate,
  getUploadTotalValidatedCount,
  getValidUploads,
  selectAllStudents,
  setFinishedUploading,
  setIsUploading,
  setIsValidating,
  setTotalUploadsToValidate,
  uploadStudent,
} from '../../../store/students.slice'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountId } from '../../../store/session.slice'
import { requiredFields } from '../../../constants/uploadFields'
import { selectAllTardies } from '../../../store/tardies.slice'
import { isAdmin } from '../../../helpers'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const StudentUploadForm = props => {
  const allStudents = useSelector(getAllValidatedUploads)
  const validStudents = useSelector(getValidUploads)
  const invalidStudents = useSelector(getInvalidUploads)
  const validatedCount = useSelector(getUploadTotalValidatedCount)

  const isValidating = useSelector(getIsValidatingUploads)
  const totalToValidate = useSelector(getTotalUploadsToValidate)

  const isUploading = useSelector(getStudentsUploading)
  const uploadedCount = useSelector(getStudentsUploadedCount)
  const uploadComplete = useSelector(getStudentsUploadComplete)
  const accountId = useSelector(getAccountId)
  const classes = useStyles()
  const students = useSelector(selectAllStudents)
  const tardies = useSelector(selectAllTardies)

  const dispatch = useDispatch()

  function getAsText(fileToRead) {
    var reader = new FileReader()
    // Read file into memory as UTF-8
    reader.readAsText(fileToRead)
    // Handle errors load
    reader.onload = loadHandler
    reader.onerror = errorHandler
  }

  function getExistingStudent(student) {
    const index = students.findIndex(function (s) {
      // .where('firstName', '==', student.firstName)
      // .where('lastName', '==', student.lastName)
      return s.badgeId === student.badgeId && s.accountId === accountId
    })
    if (index > -1) {
      return students[index]
    }
    return null
  }

  function getExistingTardyCount(student) {
    const result = tardies.filter(t => t.badgeId === student.badgeId && t.accountId === accountId)
    return result.length
  }

  const delay = ms => new Promise(res => setTimeout(res, ms))

  function includesAllRequiredFields(student) {
    for (var i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i]
      if (student[field] === '') return false
    }
    return true
  }

  function loadHandler(event) {
    var csv = event.target.result
    processData(csv)
  }

  async function processData(csv) {
    dispatch(setIsValidating(true))

    var allTextLines = csv.split(/\r\n|\n/)
    dispatch(setTotalUploadsToValidate(allTextLines.length - 1))

    var headerRow = allTextLines[0].split(',')
    console.info(headerRow)
    console.info('beginning validation...')

    for (var i = 0; i < allTextLines.length; i++) {
      if (i > 0) {
        var dataRow = allTextLines[i].split(',')
        if (dataRow.length === headerRow.length) {
          var student = {}

          for (var j = 0; j < dataRow.length; j++) {
            if (headerRow[j] !== '') {
              var label = headerRow[j]
              var value = dataRow[j]
              student[label] = value
            }
          }

          const existingStudent = getExistingStudent(student)

          student['exists'] = existingStudent !== null
          student['fieldsValidated'] = includesAllRequiredFields(student)

          student['accountId'] = accountId

          if (student['tardyCount'] === undefined) {
            student['tardyCount'] =
              existingStudent != null ? existingStudent.tardyCount : getExistingTardyCount(student)
          }

          student.fieldsValidated && !student.exists
            ? dispatch(addValidUpload(student))
            : dispatch(addInvalidUpload(student))

          await delay(1)
        }
      }
    }

    console.info('validation complete.')

    dispatch(setIsValidating(false))
  }

  function errorHandler(evt) {
    if (evt.target.error.name === 'NotReadableError') {
      alert('Cannot read file !')
    }
  }

  const handleDrop = files => {
    if (window.FileReader) {
      getAsText(files[0])
    } else {
      alert('FileReader is not supported in this browser.')
    }
  }

  const handleDownloadRequiredFieldsTemplate = template => {
    props.firebase.doDownloadRequiredFieldsStudentTemplate()
  }

  const handleDownloadAllFieldsTemplate = template => {
    props.firebase.doDownloadAllFieldsStudentTemplate()
  }

  const handleUpload = () => {
    dispatch(setIsUploading())
    validStudents.forEach(async student => {
      dispatch(uploadStudent({ firebase: props.firebase, student, accountId }))
      await delay(1)
    })
    dispatch(setFinishedUploading())
  }

  return (
    <Grid container justify='center' alignContent='center'>
      <Grid item xs={3} />
      {!isValidating && (
        <React.Fragment>
          <Grid item xs={3}>
            <Button fullWidth color='primary' onClick={handleDownloadAllFieldsTemplate}>
              Download Student Template (All Fields)
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth color='primary' onClick={handleDownloadRequiredFieldsTemplate}>
              Download Student Template (Required Fields Only)
            </Button>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={12}>
            <Dropzone handle={handleDrop} />
          </Grid>
        </React.Fragment>
      )}
      {/* {isValidating && <CircularProgress />} */}
      {totalToValidate !== 0 && isValidating && (
        <Grid container justify='center' alignItems='center' alignContent='center'>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Chip
              color='primary'
              label={`validating ${validatedCount} of ${totalToValidate}`}
              icon={<FaceIcon />}
            />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      )}
      {validStudents.length > 0 && (isUploading || uploadComplete) && (
        <Grid container justify='center' alignItems='center' alignContent='center'>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Chip
              color='primary'
              label={`uploading ${uploadedCount} of ${validStudents.length}`}
              icon={<FaceIcon />}
            />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      )}
      {allStudents.length !== 0 && !isValidating && !isUploading && !uploadComplete && (
        <Grid container justify='center' alignItems='center' alignContent='center'>
          {isUploading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
          <Grid item xs={4}>
            <Chip label={`# invalid: ${invalidStudents.length}`} color='secondary' />
            <Chip label={`# valid: ${validStudents.length}`} color='primary' />
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              disabled={validStudents.length === 0}
              type='submit'
              className={classes.submit}
              onClick={handleUpload}
            >
              Upload {validStudents.length} Students
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      )}
      {allStudents.length !== 0 && (
        <Grid item>
          <StudentUploadPreview students={allStudents} />
        </Grid>
      )}
    </Grid>
  )
}

const condition = authUser => isAdmin(authUser)

export default compose(withAuthorization(condition)(StudentUploadForm))
