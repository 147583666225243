import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import { orange } from '@material-ui/core/colors'
import EditIcon from '@material-ui/icons/Edit'
import BusinessIcon from '@material-ui/icons/Business'
import React, { useState } from 'react'
import LocationEditDialog from './locationSettingsEditDialog'
import { useSelector } from 'react-redux'
import { getAddress } from '../../../store/account.slice'
import { getAccountName } from '../../../store/account.slice'

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  iconButton: {
    padding: 10,
    color: orange,
  },
  cardHeader: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  cardAction: {
    alignSelf: 'center',
  },
  listRow: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}))

const LocationCard = ({ onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false)
  const classes = useStyles()
  const address = useSelector(getAddress)
  const name = useSelector(getAccountName)

  return (
    <React.Fragment>
      <Card variant='outlined'>
        <CardHeader
          title='Location'
          action={
            <IconButton
              className={classes.iconButton}
              aria-label='edit location'
              onClick={() => setIsEditing(true)}
            >
              <EditIcon />
            </IconButton>
          }
          classes={{
            title: classes.cardHeader,
            action: classes.cardAction,
          }}
          avatar={
            <Avatar className={classes.avatar}>
              <BusinessIcon />
            </Avatar>
          }
        />
        {address && (
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {name}
              </Grid>
              <Grid item xs={12}>
                {address.street1}
              </Grid>
              {address.street2 && (
                <Grid item xs={12}>
                  {address.street2}
                </Grid>
              )}
              {(address.city || address.state || address.zip) && (
                <Grid item xs={12}>
                  {address.city}
                  {address.city && address.state && ', '}
                  {address.state} {address.zip}
                </Grid>
              )}
            </Grid>
          </CardContent>
        )}
      </Card>
      {address && (
        <LocationEditDialog
          address={address}
          name={name}
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onUpdate={onUpdate}
        />
      )}
    </React.Fragment>
  )
}

export default LocationCard
