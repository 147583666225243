import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit'
import { ipcRenderer } from '../renderer'

const detentionsAdapter = createEntityAdapter()

const initialState = detentionsAdapter.getInitialState({
  loading: false,
  adding: false,
  deletings: false,
  selected: null,
})

export const fetchDetentions = createAsyncThunk('detentions/fetch', async (args, thunkAPI) => {
  try {
    return await args.firebase.doGetDetentions(args.accountId).then(res => res)
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const addDetention = createAsyncThunk('detentions/add', async (args, thunkAPI) => {
  try {
    var tardyResponse = await args.firebase
      .doAddTardy(args?.period, args.authUser, args.student, args.consequence)
      .then(res => res)

    const allTardies = await args.firebase.doGetTardiesForStudent(
      args.student.badgeId,
      args.student.accountId
    )

    var response = await args.firebase
      .doAddDetention(args?.period, args.authUser, tardyResponse.student, tardyResponse.tardy.id)
      .then(res => res)

    if (ipcRenderer) {
      const printerName = thunkAPI.getState().session?.selectedPrinter?.name
      const titleToUse = args.msgs.detentionTitle
      const descriptionToUse = args.msgs.detetntionDescription

      ipcRenderer.invoke(
        'print-consequence',
        args.consequence,
        //'print-test-page'
        printerName,
        allTardies,
        response.student,
        titleToUse,
        descriptionToUse
      )
    }
    return {
      detention: response.detention,
      student: response.student,
      message: `detention added for ${args.student.firstName} ${args.student.lastName}`,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const deleteDetention = createAsyncThunk(
  'detentions/deleteDetention',
  async (args, thunkAPI) => {
    try {
      await args.firebase.doDeleteDetention(args.detentionId)
      return {
        id: args.detentionId,
        message: `detention removed for ${args.studentFirstName} ${args.studentLastName}`,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const completeDetention = createAsyncThunk(
  'detentions/completeDetention',
  async (args, thunkAPI) => {
    try {
      const detention = await args.firebase.doCompleteDetention(args.detentionId)
      return {
        id: args.detentionId,
        detention,
        message: `detention completed for ${args.studentFirstName} ${args.studentLastName}`,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteAllDetentions = createAsyncThunk(
  'account/deleteAllDetentions',
  async (args, thunkAPI) => {
    try {
      return await args.firebase.doDeleteAllDetentions().then(res => res)
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

// export const fetchDetentionsForStudent = createAsyncThunk(
//   'detentions/fetchForStudent',
//   async (args, thunkAPI) => {
//     try {
//       return await args.firebase.doGetDetentionsForStudent(args.studentId, args.accountId).then(res => {
//
//         return res
//       })
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message)
//     }
//   }
// )

const detentionsSlice = createSlice({
  name: 'detentions',
  initialState,
  reducers: {
    setSelectedDetentions: (state, action) => {
      state.selected = action.payload
    },
    setDetentionsError: (state, action) => {
      state.error = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDetentions.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchDetentions.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload) detentionsAdapter.setAll(state, action.payload)
      })
      .addCase(fetchDetentions.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(addDetention.pending, (state, action) => {
        state.adding = true
      })
      .addCase(addDetention.fulfilled, (state, action) => {
        const { detention } = action.payload
        state.adding = false
        detentionsAdapter.addOne(state, detention)
      })
      .addCase(addDetention.rejected, (state, action) => {
        state.adding = false
      })
      .addCase(completeDetention.pending, (state, action) => {
        state.completing = true
      })
      .addCase(completeDetention.rejected, (state, action) => {
        state.completing = false
      })
      .addCase(completeDetention.fulfilled, (state, action) => {
        const { completed, completedTimestamp } = action.payload.detention
        const { id } = action.payload
        const changes = { completed, completedTimestamp }
        detentionsAdapter.updateOne(state, { id, changes })
        state.completing = false
      })
      .addCase(deleteAllDetentions.fulfilled, (state, action) => {
        state.deleting = false
        detentionsAdapter.removeAll(state)
      })
      .addCase(deleteDetention.fulfilled, (state, action) => {
        const { id } = action.payload
        state.deleting = false
        detentionsAdapter.removeOne(state, id)
      })
      .addMatcher(
        isAnyOf(deleteAllDetentions.pending, deleteDetention.pending),
        (state, action) => {
          state.deleting = true
        }
      )
      .addMatcher(
        isAnyOf(deleteAllDetentions.rejected, deleteDetention.rejected),
        (state, action) => {
          state.deleting = false
        }
      )
  },
})

export const { setSelectedDetention, setDetentionsError } = detentionsSlice.actions

export default detentionsSlice.reducer

export const { selectAll: selectAllDetentions } = detentionsAdapter.getSelectors(
  state => state.detentions
)

export const getDetentionsState = state => state
export const getDetentionsCount = state => state.detentions.ids.length
export const getDetentionsLoading = state => state.detentions.loading
export const getDetentionsDeleting = state => state.detentions.deleting
export const getDetentionsAdding = state => state.detentions.adding

export const allDetentions = createSelector(selectAllDetentions, getDetentionsState)
