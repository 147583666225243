import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import compose from 'recompose/compose'
import { getAuthUser } from '../../../store/session.slice'
import { getTardiesLoading } from '../../../store/tardies.slice'
import { withAuthorization } from '../../higher-order/Session'
import { TardyEditModal } from '../Modals'

const TardyGrid = props => {
  const [selectedTardy, setSelectedTardy] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const loading = useSelector(getTardiesLoading)
  const tardies = props.tardies
  const tableRef = React.useRef()
  const authUser = useSelector(getAuthUser)
  const handleSearchChange = event => {
    var count = tableRef.current.state.data.length

    if (count === 1) {
      handleOpenDetails(tableRef.current.state.data[0])
    }
  }

  const handleOpenDetails = student => {
    setSelectedTardy(student)
    setModalIsOpen(true)
  }

  const handleModalClose = () => {
    setModalIsOpen(false)
  }

  return (
    <React.Fragment>
      {tardies && (
        <MaterialTable
          title='Tardies'
          isLoading={loading}
          columns={[
            {
              title: 'Occurred',
              field: 'timestamp',
              type: 'datetime',
              defaultSort: 'desc',
              hidden: true,
            },
            {
              title: 'Occurred',
              field: 'timestampDisplay',
              type: 'string',
            },
            {
              title: 'Period',
              field: 'period',
              type: 'numeric',
            },
            {
              title: 'First Name',
              field: 'studentFirstName',
              type: 'string',
            },
            {
              title: 'Last Name',
              field: 'studentLastName',
              type: 'string',
            },
            {
              title: 'Badge Id',
              field: 'badgeId',
              type: 'string',
            },
            {
              title: 'Consequence',
              field: 'consequence',
              type: 'string',
            },
            {
              title: 'Tardy #',
              field: 'tardyCount',
              type: 'string',
            },
          ]}
          // below is a hack for material-table, since it is adding tabledata to objs in db
          data={tardies.map(x => Object.assign({}, x))}
          actions={[
            {
              iconProps: { style: { color: 'orange' } },
              icon: 'launch',
              tooltip: 'Open',
              onClick: (event, rowData) => {
                handleOpenDetails(rowData)
              },
            },
          ]}
          options={{
            paging: true,
            pageSize: 10,
            paginationType: 'normal',
            searchFieldVariant: 'outlined',
            search: true,
            searchAutoFocus: true,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'SSS-TardyApp-TardyRecords-' + Date().toLocaleLowerCase(),
            dateSetting: 'en-US',
            tableLayout: 'auto',
          }}
          tableRef={tableRef}
          onRowClick={(event, rowData) => handleOpenDetails(rowData)}
          onSearchChange={handleSearchChange}
        />
      )}
      {modalIsOpen && selectedTardy && (
        <TardyEditModal
          open={modalIsOpen}
          tardy={{ ...selectedTardy }}
          authUser={authUser}
          handleClose={handleModalClose}
        />
      )}
    </React.Fragment>
  )
}
const condition = authUser => authUser

export default compose(withAuthorization(condition))(TardyGrid)
