import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { useSpring, animated } from 'react-spring/web.cjs' // web.cjs is required for IE 11 support
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from '@reduxjs/toolkit'
import { withFirebase } from '../../higher-order/Firebase'
import { getAccountId } from '../../../store/session.slice'
import { inviteUser } from '../../../store/users.slice'
import { getSettings } from '../../../store/account.slice'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
}

const UserInviteModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const account = useSelector(getSettings)
  const accountId = useSelector(getAccountId)

  const handleInviteUser = () => {
    dispatch(
      inviteUser({
        accountId: accountId,
        accountName: account.name,
        firebase: props.firebase,
        user: props.user,
      })
    )
    props.handleClose()
  }
  return (
    <div>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            {props.user && <h2 id='spring-modal-title'>{props.user.email}</h2>}
            {props.user && (
              <ul id='spring-modal-description'>
                <li>Id: {props.user.id}</li>
                <li>Role: {props.user.role}</li>
                <li>InvitedOn: {props.user.invitedOn}</li>
                <li>AcceptedOn: {props.user.acceptedOn}</li>
              </ul>
            )}

            <Button variant='contained' color='secondary' onClick={props.handleClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={handleInviteUser}>
              Send Invite?
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default compose(withFirebase)(UserInviteModal)
