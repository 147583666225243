import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'

const usersAdapter = createEntityAdapter()

const initialState = usersAdapter.getInitialState({
  loading: false,
  creating: false,
  inviting: false,
  revoking: false,
  filter: 'all', //'pending','invited','accepted',etc
})
export const fetchUsers = createAsyncThunk('users/fetchUsers', async (args, thunkAPI) => {
  return await args.firebase
    .users()
    .where('accountId', '==', args.accountId)
    .get()
    .then(snapshot => {
      var results = []
      snapshot.forEach(doc => {
        var data = doc.data()
        var id = doc.id
        results.push({ id, ...data })
      })
      return results
    })
})

export const createUser = createAsyncThunk('users/createOne', async (args, thunkAPI) => {
  try {
    const res = await args.firebase.doCreateUser(args.user)
    return { user: res, message: 'User created' }
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const updateUser = createAsyncThunk('users/updateOne', async (args, thunkAPI) => {
  try {
    const res = await args.firebase.doUpsertUser(args.user)
    return { updatedUser: res, message: `Updated ${res.email}` }
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const inviteUser = createAsyncThunk('users/invite', async (args, thunkAPI) => {
  try {
    return await args.firebase.doInviteUser(args.accountId, args.accountName, args.user)
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const acceptInvite = createAsyncThunk('users/acceptInvite', async (args, thunkAPI) => {
  try {
    return await args.firebase.doAcceptUserInvite(args.id, args.email, args.pass, args.accountId)
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

// TODO: Disinvite User
export const revokeAccess = createAsyncThunk('users/revokeAccess', async (args, thunkAPI) => {
  try {
    //do revoke access
    return args.firebase.doRevokeUserAccess(args.userId, args.accountId, args.byUserId)
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const deleteUser = createAsyncThunk('users/deleteOne', async (args, thunkAPI) => {
  try {
    // do delete user
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

// TODO: Delete User

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false

        usersAdapter.setAll(state, action.payload)
      })
      .addCase(fetchUsers.rejected, state => {
        state.loading = false
      })
      .addCase(createUser.pending, (state, action) => {
        state.creating = true
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const id = action.payload.user
        const { user } = action.meta.arg

        state.creating = false
        user.id = id

        usersAdapter.addOne(state, user)
      })
      .addCase(createUser.rejected, (state, action) => {
        state.creating = false
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const { updatedUser } = action.payload
        usersAdapter.upsertOne(state, updatedUser)
      })
      .addCase(inviteUser.pending, (state, action) => {
        state.inviting = true
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        const { user } = action.payload

        state.inviting = false

        usersAdapter.upsertOne(state, user)
      })
      .addCase(inviteUser.rejected, (state, action) => {
        state.inviting = false
      })
      .addCase(revokeAccess.pending, (state, action) => {
        state.revoking = true
      })
      .addCase(revokeAccess.fulfilled, (state, action) => {
        state.revoking = false
        const { user } = action.payload
        usersAdapter.upsertOne(state, user)
      })
      .addCase(revokeAccess.rejected, (state, action) => {
        state.revoking = false
      })
  },
})

export default usersSlice.reducer

export const { selectAll: selectAllUsers, selectById: selectUserById } = usersAdapter.getSelectors(
  state => state.users
)

export const usersLoading = state => state.users.loading
export const usersCreating = state => state.users.creating
export const usersInviting = state => state.users.inviting

export const allUsers = createSelector(selectAllUsers, users => users)
