import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit'
import { ipcRenderer } from '../renderer'

const officeVisitsAdapter = createEntityAdapter()

const initialState = officeVisitsAdapter.getInitialState({
  adding: false,
  loading: false,
  deleting: false,
  selected: null,
})

export const fetchOfficeVisits = createAsyncThunk('officeVisits/fetch', async (args, thunkAPI) => {
  try {
    return await args.firebase.doGetOfficeVisits(args.accountId).then(res => {
      return res
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const addOfficeVisit = createAsyncThunk('officeVisits/add', async (args, thunkAPI) => {
  try {
    var tardyResponse = await args.firebase
      .doAddTardy(args?.period, args.authUser, args.student, args.consequence)
      .then(res => res)

    const allTardies = await args.firebase.doGetTardiesForStudent(
      args.student.badgeId,
      args.student.accountId
    )

    var response = await args.firebase
      .doAddOfficeVisit(args?.period, args.authUser, tardyResponse.student, tardyResponse.tardy.id)
      .then(res => res)

    if (ipcRenderer) {
      const printerName = thunkAPI.getState().session?.selectedPrinter?.name
      const titleToUse = args.msgs.officeTitle
      const descriptionToUse = args.msgs.officeDescription

      ipcRenderer.invoke(
        'print-consequence',
        args.consequence,
        //'print-test-page'
        printerName,
        allTardies,
        response.student,
        titleToUse,
        descriptionToUse
      )
    }
    return {
      officeVisit: response.officeVisit,
      student: response.student,
      message: `office referral added for ${args.student.firstName} ${args.student.lastName}`,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const deleteOfficeVisit = createAsyncThunk(
  'officeVisits/deleteOfficeVisit',
  async (args, thunkAPI) => {
    try {
      await args.firebase.doDeleteOfficeVisit(args.officeVisitId)
      return {
        id: args.officeVisitId,
        message: `office visit removed for ${args.studentFirstName} ${args.studentLastName}`,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const completeOfficeVisit = createAsyncThunk(
  'officeVisits/completeOfficeVisit',
  async (args, thunkAPI) => {
    try {
      const officeVisit = await args.firebase.doCompleteOfficeVisit(args.officeVisitId)
      return {
        id: args.officeVisitId,
        officeVisit,
        message: `office visit completed for ${args.studentFirstName} ${args.studentLastName}`,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const deleteAllOfficeVisits = createAsyncThunk(
  'officeVisits/deleteAllOfficeVisits',
  async (args, thunkAPI) => {
    try {
      return await args.firebase.doDeleteAllOfficeVisits().then(res => res)
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
// export const fetchOfficeVisitsForStudent = createAsyncThunk(
//   'officeVisits/fetchForStudent',
//   async (args, thunkAPI) => {
//     try {
//       return await args.firebase.doGetOfficeVisitsForStudent(args.studentId, args.accountId).then(res => {
//
//         return res
//       })
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message)
//     }
//   }
// )

const officeVisitsSlice = createSlice({
  name: 'officeVisits',
  initialState,
  reducers: {
    setSelectedOfficeVisits: (state, action) => {
      state.selected = action.payload
    },
    setOfficeVisitsError: (state, action) => {
      state.error = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOfficeVisits.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchOfficeVisits.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload) officeVisitsAdapter.setAll(state, action.payload)
      })
      .addCase(fetchOfficeVisits.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(addOfficeVisit.pending, (state, action) => {
        state.adding = true
      })
      .addCase(addOfficeVisit.fulfilled, (state, action) => {
        const { officeVisit } = action.payload
        state.adding = false
        officeVisitsAdapter.addOne(state, officeVisit)
      })
      .addCase(addOfficeVisit.rejected, (state, action) => {
        state.adding = false
      })
      .addCase(deleteAllOfficeVisits.fulfilled, (state, action) => {
        state.deleting = false
        officeVisitsAdapter.removeAll(state)
      })
      .addCase(deleteOfficeVisit.fulfilled, (state, action) => {
        const { id } = action.payload
        state.deleting = false
        officeVisitsAdapter.removeOne(state, id)
      })
      .addCase(completeOfficeVisit.pending, (state, action) => {
        state.completing = true
      })
      .addCase(completeOfficeVisit.rejected, (state, action) => {
        state.completing = false
      })
      .addCase(completeOfficeVisit.fulfilled, (state, action) => {
        const { completed, completedTimestamp } = action.payload.officeVisit
        const { id } = action.payload
        const changes = { completed, completedTimestamp }
        officeVisitsAdapter.updateOne(state, { id, changes })
        state.completing = false
      })
      .addMatcher(
        isAnyOf(deleteAllOfficeVisits.pending, deleteOfficeVisit.pending),
        (state, action) => {
          state.deleting = true
        }
      )
      .addMatcher(
        isAnyOf(deleteAllOfficeVisits.rejected, deleteOfficeVisit.rejected),
        (state, action) => {
          state.deleting = false
        }
      )
  },
})

export const { setSelectedOfficeVisit, setOfficeVisitsError } = officeVisitsSlice.actions

export default officeVisitsSlice.reducer

export const { selectAll: selectAllOfficeVisits } = officeVisitsAdapter.getSelectors(
  state => state.officeVisits
)

export const getOfficeVisitsState = state => state
export const getOfficeVisitsCount = state => state.officeVisits.ids.length
export const getOfficeVisitsLoading = state => state.officeVisits.loading
export const getOfficeVisitsDeleting = state => state.officeVisits.deleting
export const getOfficeVisitsAdding = state => state.officeVisits.adding

export const allOfficeVisits = createSelector(selectAllOfficeVisits, getOfficeVisitsState)
