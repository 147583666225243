import React, { useEffect } from 'react'
import { withAuthorization } from '../../components/higher-order/Session'
import { withFirebase } from '../../components/higher-order/Firebase'
import compose from 'recompose/compose'
import { Container } from '@material-ui/core'
import { OfficeVisitGrid } from '../../components/utility/Grids'
import { useSelector, useDispatch } from 'react-redux'
import { allOfficeVisits, fetchOfficeVisits } from '../../store/officeVisits.slice'
import { getAccountId } from '../../store/session.slice'

const OfficeVisitsPage = props => {
  const accountId = useSelector(getAccountId)
  const officeVisits = useSelector(allOfficeVisits)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchOfficeVisits({ firebase: props.firebase, accountId: accountId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component='main' maxWidth='lg'>
      {officeVisits && <OfficeVisitGrid officeVisits={[...officeVisits]} />}
    </Container>
  )
}

const condition = authUser => authUser

export default compose(withAuthorization(condition), withFirebase)(OfficeVisitsPage)
