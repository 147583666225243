import React, { useEffect } from 'react'
import { withAuthorization } from '../../components/higher-order/Session'
import { withFirebase } from '../../components/higher-order/Firebase'
import compose from 'recompose/compose'
import { Container } from '@material-ui/core'
import { TardyGrid } from '../../components/utility/Grids'
import { useSelector, useDispatch } from 'react-redux'
import { allTardies, fetchTardies } from '../../store/tardies.slice'
import { getAccountId } from '../../store/session.slice'
import { isAnyUser } from '../../helpers'

const TardiesPage = props => {
  const accountId = useSelector(getAccountId)
  const tardies = useSelector(allTardies)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTardies({ firebase: props.firebase, accountId: accountId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component='main' maxWidth='lg'>
      {tardies && <TardyGrid tardies={[...tardies]} />}
    </Container>
  )
}
const condition = authUser => isAnyUser(authUser)

export default compose(withAuthorization(condition), withFirebase)(TardiesPage)
