import React from 'react'

import { PasswordForgetForm } from '../PasswordForget'
import PasswordChangeForm from '../PasswordChange'
import { withAuthorization } from '../../components/higher-order/Session'
import { CustomizedBreadcrumbs } from '../../components/utility/Breadcrumbs'
import { useSelector } from 'react-redux'
import compose from 'recompose/compose'
import { makeStyles, Container, Typography } from '@material-ui/core'
import { getAuthUser } from '../../store/session.slice'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
}))

const AccountPage = () => {
  const classes = useStyles()
  const authUser = useSelector(getAuthUser)
  return (
    <Container component='main' maxWidth='md'>
      <CustomizedBreadcrumbs />
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          Username: {authUser.username}
        </Typography>
        <Typography component='h1' variant='h5'>
          Email: {authUser.email}
        </Typography>
        <PasswordForgetForm />
        <PasswordChangeForm />
      </div>
    </Container>
  )
}

const condition = authUser => authUser

export default compose(withAuthorization(condition))(AccountPage)
