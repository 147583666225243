import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getConsequences,
  getSettingsWorking,
  updateConsequences,
} from '../../../store/account.slice'
import MaterialTable from 'material-table'
import compose from 'recompose/compose'
import { withFirebase } from '../../higher-order/Firebase'
import { getAccountId } from '../../../store/session.slice'
import { withAuthorization } from '../../higher-order/Session'
import { isAdmin } from '../../../helpers'

const ConsequenceGrid = props => {
  const dispatch = useDispatch()
  const consequences = useSelector(getConsequences)
  const loading = useSelector(getSettingsWorking)
  const accountId = useSelector(getAccountId)
  const [columns] = useState([
    { title: '#', render: rowData => rowData.tableData.id + 1 },
    {
      title: 'Consequence',
      field: 'name',
      lookup: { tardy: 'tardy', detention: 'detention', office: 'office' },
    },
  ])
  const [data, setData] = useState(consequences !== undefined ? consequences : [])

  const handleSetData = data => {
    setData([...data])
    dispatch(
      updateConsequences({
        updatedConsequences: [...data],
        accountId,
        firebase: props.firebase,
      })
    )
  }

  return (
    data && (
      <MaterialTable
        title='Consequences'
        isLoading={loading}
        columns={columns}
        data={data.map(x => Object.assign({}, x))}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const existingData = [...data]
                existingData.push(newData)
                handleSetData([...existingData])

                resolve()
              }, 1000)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data]
                const index = oldData.tableData.id
                dataUpdate[index] = newData
                handleSetData([...dataUpdate])

                resolve()
              }, 1000)
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...data]
                const index = oldData.tableData.id
                dataDelete.splice(index, 1)
                handleSetData([...dataDelete])

                resolve()
              }, 1000)
            }),
        }}
        options={{
          search: false,
          sorting: false,
        }}
      />
    )
  )
}

const condition = authUser => isAdmin(authUser)

export default compose(withFirebase, withAuthorization(condition))(ConsequenceGrid)
