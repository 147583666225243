import React from 'react'

import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import { withRouter } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'
import { connect } from 'react-redux'

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          if (!condition(authUser)) {
            console.info('routing to signin')
            this.props.history.push(ROUTES.SIGN_IN)
          }
        },
        () => {
          console.info('routing to signin')
          this.props.history.push(ROUTES.SIGN_IN)
        }
      )
    }

    componentWillUnmount() {
      this.listener()
    }
    render() {
      return condition(this.props.authUser) ? <Component {...this.props} /> : null
    }
  }

  const mapStateToProps = state => ({
    authUser: state.session.authUser,
  })

  return compose(withRouter, withFirebase, connect(mapStateToProps))(WithAuthorization)
}

export default withAuthorization
