import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import { TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { updateAccountName } from '../../../store/account.slice'
import { getAccountId } from '../../../store/session.slice'
import { withFirebase } from '../../higher-order/Firebase'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 160,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}))

const LocationEditDialog = ({ name, address, isOpen, onUpdate, onClose, firebase }) => {
  const classes = useStyles()
  const [street1, setStreet1] = useState(address.street1)
  const [street2, setStreet2] = useState(address.street2)
  const [city, setCity] = useState(address.city)
  const [state, setState] = useState(address.state)
  const [zip, setZip] = useState(address.zip)
  const [accountName, setAccountName] = useState(name)
  const accountId = useSelector(getAccountId)
  const dispatch = useDispatch()

  const handleSubmit = event => {
    event.preventDefault()

    dispatch(updateAccountName({ accountId, updatedName: accountName, firebase }))
    // dispatch(updateAddress({ accountId, updatedAddress, firebase: props.firebase }))

    // TODO: clean this up!
    onUpdate({ street1, street2, city, state, zip })
    handleClose()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth='md'
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id='max-width-dialog-title'>School Info</DialogTitle>
        <DialogContent>
          <DialogContentText>Update Account Name and Address</DialogContentText>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <FormControl className={classes.formControl}>
              <TextField
                name='editName'
                value={accountName}
                onChange={e => setAccountName(e.target.value)}
                type='text'
                label='Account Name'
                variant='outlined'
                margin='normal'
                required
              />{' '}
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                name='editStreet1'
                value={street1}
                onChange={e => setStreet1(e.target.value)}
                type='text'
                label='Street 1'
                variant='outlined'
                margin='normal'
                autoComplete='address-line1'
              />{' '}
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                name='editStreet2'
                value={street2}
                onChange={e => setStreet2(e.target.value)}
                type='text'
                label='Street 2'
                variant='outlined'
                margin='normal'
                autoComplete='address-line2'
              />{' '}
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                name='editCity'
                value={city}
                onChange={e => setCity(e.target.value)}
                type='text'
                label='City'
                variant='outlined'
                margin='normal'
                autoComplete='address-level2'
              />{' '}
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                name='editState'
                value={state}
                onChange={e => setState(e.target.value)}
                type='text'
                label='State'
                variant='outlined'
                margin='normal'
                autoComplete='address-level1'
              />{' '}
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                name='editZip'
                value={zip}
                onChange={e => setZip(e.target.value)}
                type='text'
                label='Zip Code'
                variant='outlined'
                margin='normal'
                autoComplete='postal-code'
              />{' '}
            </FormControl>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              fullWidth
              className={classes.submit}
            >
              Save
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withFirebase(LocationEditDialog)
