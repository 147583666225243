import { Typography } from "@material-ui/core";

export const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      Simple School Solutions
      {" " + new Date().getFullYear()}
      {"."}
    </Typography>
  );
};
