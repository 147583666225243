import React, { useEffect } from 'react'
import { withAuthorization } from '../../components/higher-order/Session'
import { withFirebase } from '../../components/higher-order/Firebase'
import compose from 'recompose/compose'
import { Container } from '@material-ui/core'
import { DetentionGrid } from '../../components/utility/Grids'
import { useSelector, useDispatch } from 'react-redux'
import { allDetentions, fetchDetentions } from '../../store/detentions.slice'
import { getAccountId } from '../../store/session.slice'

const DetentionsPage = props => {
  const accountId = useSelector(getAccountId)
  const detentions = useSelector(allDetentions)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDetentions({ firebase: props.firebase, accountId: accountId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component='main' maxWidth='lg'>
      {detentions && <DetentionGrid detentions={[...detentions]} />}
    </Container>
  )
}

const condition = authUser => authUser

export default compose(withAuthorization(condition), withFirebase)(DetentionsPage)
