import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit'
import { ipcRenderer } from '../renderer'

const tardiesAdapter = createEntityAdapter()

const initialState = tardiesAdapter.getInitialState({
  loading: false,
  adding: false,
  deleting: false,
  resetting: false,
  selected: null,
})

export const fetchTardies = createAsyncThunk('tardies/fetch', async (args, thunkAPI) => {
  try {
    return await args.firebase.doGetTardies(args.accountId).then(res => res)
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const fetchTardiesForStudent = createAsyncThunk(
  'tardies/fetchForStudent',
  async (args, thunkAPI) => {
    try {
      return await args.firebase.doGetTardiesForStudent(args.badgeId, args.accountId).then(res => {
        return res
      })
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

// export const printTardy = createAsyncThunk(
//   'tardies/printNew',
//   async (args, thunkAPI) => {
//     const printerName = thunkAPI.getState().session.selectedPrinter.name
//     try {
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message)
//     }
//   }
// )

export const addTardy = createAsyncThunk('tardies/add', async (args, thunkAPI) => {
  try {
    var tardyResponse = await args.firebase
      .doAddTardy(args?.period, args.authUser, args.student, args.consequence)
      .then(res => res)

    const allTardies = await args.firebase.doGetTardiesForStudent(
      args.student.badgeId,
      args.student.accountId
    )

    if (ipcRenderer) {
      const printerName = thunkAPI.getState().session?.selectedPrinter?.name
      const titleToUse = args.msgs.tardyTitle
      const descriptionToUse = args.msgs.tardyDescription

      ipcRenderer.invoke(
        'print-consequence',
        args.consequence,
        printerName,
        allTardies,
        args.student,
        titleToUse,
        descriptionToUse
      )
    }
    return {
      tardy: tardyResponse.tardy,
      student: tardyResponse.student,
      message: `tardy added for ${args.student.firstName} ${args.student.lastName}`,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const deleteTardy = createAsyncThunk('tardies/deleteTardy', async (args, thunkAPI) => {
  try {
    const res = await args.firebase.doDeleteTardy(args.tardyId, args.authUser)
    return {
      id: args.tardyId,
      student: res.student,
      message: `tardy removed for ${res?.student?.firstName} ${res?.student?.lastName}`,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const deleteAllTardies = createAsyncThunk(
  'account/deleteAllTardies',
  async (args, thunkAPI) => {
    try {
      const res = await args.firebase.doDeleteAllTardies()
      return {
        message: res.message,
        students: res.students,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

// export const fetchTardiesForStudent = createAsyncThunk(
//   'tardies/fetchForStudent',
//   async (args, thunkAPI) => {
//     try {
//       return await args.firebase.doGetTardiesForStudent(args.studentId, args.accountId).then(res => {
//
//         return res
//       })
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message)
//     }
//   }
// )

const tardiesSlice = createSlice({
  name: 'tardies',
  initialState,
  reducers: {
    setSelectedTardy: (state, action) => {
      state.selected = action.payload
    },
    setTardiesError: (state, action) => {
      state.error = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTardies.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload) tardiesAdapter.setAll(state, action.payload)
      })
      .addCase(fetchTardies.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchTardies.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(fetchTardiesForStudent.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchTardiesForStudent.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(fetchTardiesForStudent.fulfilled, (state, action) => {
        state.loading = false

        tardiesAdapter.upsertMany(state, action.payload)
      })
      .addCase(addTardy.pending, (state, action) => {
        state.adding = true
      })
      .addCase(addTardy.fulfilled, (state, action) => {
        var { tardy } = action.payload
        tardiesAdapter.addOne(state, tardy)
        state.adding = false
      })
      .addCase(addTardy.rejected, (state, action) => {
        state.adding = false
      })
      .addCase(deleteTardy.fulfilled, (state, action) => {
        const { id } = action.payload
        tardiesAdapter.removeOne(state, id)
        state.loading = false
      })
      .addCase(deleteAllTardies.pending, (state, action) => {
        state.deleting = true
      })
      .addMatcher(isAnyOf(deleteAllTardies.fulfilled), (state, action) => {
        state.deleting = false
        tardiesAdapter.removeAll(state)
      })
      .addMatcher(isAnyOf(deleteAllTardies.rejected), (state, action) => {
        state.deleting = false
      })
  },
})

export const { setSelectedTardy, setTardiesError } = tardiesSlice.actions

export default tardiesSlice.reducer

export const { selectAll: selectAllTardies } = tardiesAdapter.getSelectors(state => state.tardies)

export const getTardiesState = state => state
export const getTardiesLoading = state => state.tardies.loading
export const getTardiesAdding = state => state.tardies.adding
export const getTardiesDeleting = state => state.tardies.deleting
export const getTardiesCount = state => state.tardies.ids.length
export const getTardiesByPeriod = state => {
  const periods = state.account.settings.periods
  var tardiesByPeriod = []

  periods.forEach(function (val, index) {
    const tardies = state.tardies.filter(t => t.period === index + 1)
    const entry = {}
    entry[index] = tardies
    tardiesByPeriod.push(entry)
  })
  return tardiesByPeriod
}

export const getTardiesForStudent = (badgeId, accountId) => state => {
  var vals = Object.values(state.tardies.entities)
  var results = vals.filter(x => {
    return x.badgeId === badgeId && x.accountId === accountId
  })
  return results
}
export const allTardies = createSelector(selectAllTardies, getTardiesState)
