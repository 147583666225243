import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/app/App'
import reportWebVitals from './reportWebVitals'
import Firebase, { FirebaseContext } from './components/higher-order/Firebase'
import theme from './theme'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'
import { Provider } from 'react-redux'
import store from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import ErrorBoundary from './components/app/Error'

let persistor = persistStore(store)

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <FirebaseContext.Provider value={new Firebase()}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <CssBaseline />
              <App />
            </MuiPickersUtilsProvider>
          </FirebaseContext.Provider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
