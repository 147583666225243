import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { DetentionReferralEditModal } from '../Modals'
import DeleteIcon from '@material-ui/icons/Delete'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import EditIcon from '@material-ui/icons/Edit'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import BlockIcon from '@material-ui/icons/Block'
import { useDispatch, useSelector } from 'react-redux'
import {
  completeDetention,
  deleteDetention,
  getDetentionsLoading,
} from '../../../store/detentions.slice'
import { compose } from '@reduxjs/toolkit'
import { withFirebase } from '../../higher-order/Firebase'
import { withAuthorization } from '../../higher-order/Session'

const DetentionGrid = props => {
  const dispatch = useDispatch()
  const [selectedDetention, setSelectedDetention] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const detentions = props.detentions
  const loading = useSelector(getDetentionsLoading)
  const tableRef = React.useRef()

  const handleSearchChange = event => {
    var count = tableRef.current.state.data.length

    if (count === 1) {
      handleOpenDetails(tableRef.current.state.data[0])
    }
  }

  const handleOpenDetails = detention => {
    setSelectedDetention(detention)
    setModalIsOpen(true)
  }

  const handleModalClose = () => {
    setModalIsOpen(false)
  }

  const handleCompleteDetention = data => {
    dispatch(
      completeDetention({
        firebase: props.firebase,
        detentionId: data.id,
        studentFirstName: data.studentFirstName,
        studentLastName: data.studentLastName,
      })
    )
  }

  const handleDeleteDetention = data => {
    dispatch(
      deleteDetention({
        firebase: props.firebase,
        detentionId: data.id,
        studentFirstName: data.studentFirstName,
        studentLastName: data.studentLastName,
      })
    )
  }

  return (
    <React.Fragment>
      {detentions && (
        <MaterialTable
          title='Detention Referrals'
          isLoading={loading}
          columns={[
            {
              title: 'Completed',
              field: 'completed',
              // type: 'string',
              render: rowData => {
                return rowData.completed ? <CheckCircleIcon /> : <BlockIcon />
              },
            },
            {
              title: 'IssuedSort',
              field: 'timestamp',
              type: 'datetime',
              hidden: true,
            },
            {
              title: 'Issued',
              field: 'timestampDisplay',
              type: 'datetime',
            },
            {
              title: 'Period',
              field: 'period',
              type: 'numeric',
            },
            {
              title: 'First Name',
              field: 'studentFirstName',
              type: 'string',
            },
            {
              title: 'Last Name',
              field: 'studentLastName',
              type: 'string',
            },
            {
              title: 'Badge Id',
              field: 'badgeId',
              type: 'string',
            },
            {
              title: 'Tardy #',
              field: 'tardyCount',
              type: 'string',
            },
          ]}
          // below is a hack for material-table, since it is adding tabledata to objs in db
          data={detentions.map(x => Object.assign({}, x))}
          actions={[
            {
              // iconProps: { style: { color: 'orange' } },
              // icon: 'launch',
              icon: () => <DeleteIcon style={{ color: 'red' }} />,
              tooltip: 'Delete Detention',
              onClick: (event, rowData) => {
                handleDeleteDetention(rowData)
              },
            },
            {
              icon: () => <EditIcon />,
              tooltip: 'Open Details',
              onClick: (event, rowData) => {
                handleOpenDetails(rowData)
              },
            },
            {
              icon: () => <AssignmentTurnedInIcon style={{ color: 'green' }} />,
              tooltip: 'Complete Detention',
              onClick: (event, rowData) => {
                handleCompleteDetention(rowData)
              },
            },
          ]}
          options={{
            paging: true,
            pageSize: 10,
            paginationType: 'normal',
            searchFieldVariant: 'outlined',
            search: true,
            searchAutoFocus: true,
            actionsColumnIndex: -1,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'SSS-TardyApp-DetentionRecords-' + Date().toLocaleLowerCase(),
            dateSetting: 'en-US',
            tableLayout: 'auto',
          }}
          tableRef={tableRef}
          onRowClick={(event, rowData) => handleOpenDetails(rowData)}
          onSearchChange={handleSearchChange}
        />
      )}
      {modalIsOpen && selectedDetention && (
        <DetentionReferralEditModal
          open={modalIsOpen}
          detention={{ ...selectedDetention }}
          handleClose={handleModalClose}
        />
      )}
    </React.Fragment>
  )
}

const condition = authUser => authUser

export default compose(withFirebase, withAuthorization(condition))(DetentionGrid)
