import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { useSpring, animated } from 'react-spring/web.cjs' // web.cjs is required for IE 11 support
import {
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Container,
  createMuiTheme,
  MuiThemeProvider,
  Grid,
  Switch,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'

import states from './states'
import { useDispatch } from 'react-redux'
import { deleteStudent, updateStudent } from '../../../store/students.slice'
import { compose } from 'redux'
import { withFirebase } from '../../higher-order/Firebase'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    // margin: 2,
    // // display: 'flex',
    // width: '100%', // Fix IE 11 issue.
  },
  selectBox: {
    margin: theme.spacing(5),
    minWidth: 120,
  },
}))

const theme = createMuiTheme({
  palette: {
    primary: { main: red.A400 },
  },
})

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
}

const StudentEditModal = props => {
  const [deleteApproved, setDeleteApproved] = useState(false)
  const [student, setStudent] = useState(props.student)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleUpdateField = event => {
    const fieldName = event.target.name
    const fieldValue = event.target.value
    const tempStudent = { ...student, [fieldName]: fieldValue }
    setStudent(tempStudent)
  }

  const toggleDeleteApproved = () => {
    setDeleteApproved(!deleteApproved)
  }

  const handleDelete = () => {
    dispatch(deleteStudent({ firebase: props.firebase, student }))
    props.handleClose()
  }

  const handleSubmit = () => {
    dispatch(updateStudent({ firebase: props.firebase, student }))
    props.handleClose()
  }

  const studentToSaveIsValid = () => {
    return (
      student !== props.student &&
      student.firstName !== undefined &&
      student.firstName !== '' &&
      student.lastName !== undefined &&
      student.lastName !== '' &&
      student.badgeId !== undefined &&
      student.badgeId !== '' &&
      student.gradeLevel !== undefined &&
      student.gradeLevel !== ''
    )
  }
  return (
    <div>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Container maxWidth='sm' className={classes.paper}>
            {student.firstName && student.lastName && (
              <h2 id='spring-modal-title'>
                {student.firstName} {student.lastName}
              </h2>
            )}
            <form
              id='spring-modal-description'
              className={classes.form}
              noValidate
              autoComplete='off'
              onSubmit={handleSubmit}
            >
              <TextField
                name='firstName'
                variant='filled'
                value={student.firstName}
                onChange={event => handleUpdateField(event)}
                label='First Name'
                required
                fullWidth
                error={student.firstName === undefined || student.firstName === ''}
              />
              <TextField
                name='lastName'
                variant='filled'
                value={student.lastName}
                onChange={event => handleUpdateField(event)}
                fullWidth
                required
                label='Last Name'
                error={student.lastName === undefined || student.lastName === ''}
              />
              <TextField
                name='email'
                variant='filled'
                value={student.email}
                onChange={event => handleUpdateField(event)}
                fullWidth
                label='Email'
              />
              <TextField
                name='street1'
                value={student.street1}
                variant='filled'
                onChange={event => handleUpdateField(event)}
                label='Address'
                fullWidth
              />
              <TextField
                name='city'
                value={student.city}
                variant='filled'
                onChange={event => handleUpdateField(event)}
                label='City'
              />
              <Select
                name='state'
                value={student.state}
                variant='filled'
                onChange={event => handleUpdateField(event)}
                label='State'
              >
                <InputLabel value='' disabled>
                  State
                </InputLabel>
                {states.map(state => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                name='zipCode'
                value={student.zipCode}
                variant='filled'
                onChange={event => handleUpdateField(event)}
                label='Zip Code'
              />
              <TextField
                name='gradeLevel'
                value={student.gradeLevel}
                variant='filled'
                onChange={event => handleUpdateField(event)}
                fullWidth
                required
                label='Grade Level'
                error={student.gradeLevel === undefined || student.gradeLevel === ''}
              />
              <TextField
                name='badgeId'
                value={student.badgeId}
                variant='filled'
                onChange={event => handleUpdateField(event)}
                fullWidth
                required
                label='Badge ID'
                error={student.badgeId === undefined || student.badgeId === ''}
              />
            </form>
            <Grid container direction='row' justify='space-evenly' alignItems='center'>
              <Grid item>
                <Button variant='contained' color='secondary' onClick={props.handleClose}>
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!studentToSaveIsValid()}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <MuiThemeProvider theme={theme}>
                  <Switch
                    checked={deleteApproved}
                    onChange={toggleDeleteApproved}
                    name='deleteApproved'
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!deleteApproved}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </Container>
        </Fade>
      </Modal>
    </div>
  )
}

export default compose(withFirebase)(StudentEditModal)
