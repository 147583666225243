import React, { useEffect, useState } from 'react'
import { IconButton, Snackbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import {
  getErrorMsg,
  getSuccessMsg,
  clearNotifications,
  getWarningMsg,
  getInfoMsg,
} from '../../../store/notifications.slice'

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    close: {
      padding: theme.spacing(0.5),
    },
  },
}))

export const SnackbarAlert = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const error = useSelector(getErrorMsg)
  const success = useSelector(getSuccessMsg)
  const warning = useSelector(getWarningMsg)
  const info = useSelector(getInfoMsg)

  const [show, setShow] = useState(false)
  const [messageSeverity, setSeverity] = useState('info')

  useEffect(() => {
    if (error !== null) {
      setShow(true)
      setSeverity('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (success !== null) {
      setShow(true)
      setSeverity('success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  useEffect(() => {
    if (info !== null) {
      setShow(true)
      setSeverity('info')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  useEffect(() => {
    if (warning !== null) {
      setShow(true)
      setSeverity('warning')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warning])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShow(false)
    dispatch(clearNotifications())
  }

  const handleExited = () => {
    setShow(false)
  }

  return (
    <div className={classes.root}>
      {success && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={show}
          autoHideDuration={5000}
          onClose={handleClose}
          key={messageSeverity + success}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              className={classes.close}
              onClick={handleClose}
              onExited={handleExited}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <Alert onClose={handleClose} severity={messageSeverity}>
            {success}
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={show}
          autoHideDuration={5000}
          onClose={handleClose}
          key={messageSeverity + error}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              className={classes.close}
              onClick={handleClose}
              onExited={handleExited}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <Alert onClose={handleClose} severity={messageSeverity}>
            {error}
          </Alert>
        </Snackbar>
      )}
      {warning && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={show}
          autoHideDuration={5000}
          onClose={handleClose}
          key={messageSeverity + warning}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              className={classes.close}
              onClick={handleClose}
              onExited={handleExited}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <Alert onClose={handleClose} severity={messageSeverity}>
            {warning}
          </Alert>
        </Snackbar>
      )}
      {info && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={show}
          autoHideDuration={3000}
          onClose={handleClose}
          key={messageSeverity + info}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              className={classes.close}
              onClick={handleClose}
              onExited={handleExited}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <Alert onClose={handleClose} severity={messageSeverity}>
            {info}
          </Alert>
        </Snackbar>
      )}
    </div>
  )
}
