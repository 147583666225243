import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { signUp } from './session.slice'

const accountAdapter = createEntityAdapter()

const initialState = accountAdapter.getInitialState({
  working: false,
  settings: null,
  password: {
    working: false,
  },
  history: [],
})

export const fetchSettings = createAsyncThunk('account/fetchSettings', async (args, thunkAPI) => {
  try {
    return await args.firebase
      .account(args.accountId)
      .get()
      .then(doc => {
        return doc.data()
      })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

// export const fetchMessaging = createAsyncThunk('account/fetchMessaging', async (args, thunkAPI) => {
//   try {
//     return args.firebase.doGetAccountMessaging(args.accountId)
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error.message)
//   }
// })

export const fetchHistory = createAsyncThunk('account/fetchHistory', async (args, thunkAPI) => {
  try {
    return args.firebase.doGetAccountHistory(args.accountId)
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const updateClassPeriods = createAsyncThunk(
  'account/updateClassPeriods',
  async (args, { rejectWithValue }) => {
    try {
      return await args.firebase
        .doPatchAccount(args.accountId, 'periods', args.updatedPeriods)
        .then(res => {
          return { updatedPeriods: args.updatedPeriods, message: res }
        })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateAccountName = createAsyncThunk(
  'account/updateAccountName',
  async (args, { rejectWithValue }) => {
    try {
      return await args.firebase
        .doPatchAccount(args.accountId, 'name', args.updatedName)
        .then(res => {
          return { updatedName: args.updatedName, message: res }
        })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateAddress = createAsyncThunk(
  'account/updateAddress',
  async (args, { rejectWithValue }) => {
    try {
      return await args.firebase
        .doPatchAccount(args.accountId, 'address', args.updatedAddress)
        .then(res => {
          return { updatedAddress: args.updatedAddress, message: res }
        })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateConsequences = createAsyncThunk(
  'account/updateConsequences',
  async (args, { rejectWithValue }) => {
    try {
      return await args.firebase
        .doPatchAccount(args.accountId, 'consequences', args.updatedConsequences)
        .then(res => {
          return { updatedConsequences: args.updatedConsequences, message: res }
        })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateMessaging = createAsyncThunk(
  'account/updateMessaging',
  async (args, { rejectWithValue }) => {
    try {
      return await args.firebase
        .doPatchAccount(args.accountId, 'messaging', args.updatedMessaging)
        .then(res => {
          return { updatedMessaging: args.updatedMessaging, message: res }
        })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const changePassword = createAsyncThunk(
  'account/changePassword',
  async (args, { rejectWithValue }) => {
    return await args.firebase
      .doPasswordUpdate(args.pass)
      .then(res => {
        return { message: 'Password successfully changed' }
      })
      .catch(error => {
        return rejectWithValue(error)
      })
  }
)

export const resetPassword = createAsyncThunk(
  'account/resetPassword',
  async (args, { rejectWithValue }) => {
    return await args.firebase
      .doPasswordReset(args.email)
      .then(res => {
        return { message: 'Password reset email sent' }
      })
      .catch(error => {
        return rejectWithValue(error)
      })
  }
)

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.settings = action.payload
        state.working = false
      })
      // .addCase(fetchMessaging.fulfilled, (state, action) => {
      //   state.messaging = action.payload
      //   state.working = false
      // })
      .addCase(fetchHistory.fulfilled, (state, action) => {
        state.history = action.payload
        state.working = false
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        var settings = { ...state.settings }
        settings.address = action.payload.updatedAddress
        var working = false
        Object.assign(state, { settings, working })
      })
      .addCase(updateAccountName.fulfilled, (state, action) => {
        var settings = { ...state.settings }
        settings.name = action.payload.updatedName
        var working = false
        Object.assign(state, { settings, working })
      })
      .addCase(updateClassPeriods.fulfilled, (state, action) => {
        var settings = { ...state.settings }
        settings.periods = action.payload.updatedPeriods
        var working = false
        Object.assign(state, { settings, working })
      })
      .addCase(updateConsequences.fulfilled, (state, action) => {
        var settings = { ...state.settings }
        settings.consequences = action.payload.updatedConsequences
        var working = false
        Object.assign(state, { settings, working })
      })
      .addCase(updateMessaging.fulfilled, (state, action) => {
        var settings = { ...state.settings }
        settings.messaging = action.payload.updatedMessaging
        var working = false
        Object.assign(state, { settings, working })
      })
      .addCase(signUp.fulfilled, (state, action) => {})
      .addMatcher(
        isAnyOf(
          fetchSettings.rejected,
          // fetchMessaging.rejected,
          fetchHistory.rejected,
          updateAddress.rejected,
          updateAccountName.rejected,
          updateClassPeriods.rejected,
          updateConsequences.rejected,
          resetPassword.rejected,
          changePassword.rejected
        ),
        state => {
          state.working = false
        }
      )
      .addMatcher(
        isAnyOf(
          fetchSettings.pending,
          // fetchMessaging.pending,
          fetchHistory.pending,
          updateAddress.pending,
          updateAccountName.pending,
          updateClassPeriods.pending,
          updateConsequences.pending,
          resetPassword.pending,
          changePassword.pending
        ),
        state => {
          state.working = true
        }
      )
      .addMatcher(isAnyOf(resetPassword.fulfilled, changePassword.fulfilled), state => {
        state.working = false
      })
  },
})

export default accountSlice.reducer

export const getIsAccountWorking = state => state.account.working
export const getSettings = state => state.account.settings
export const getPeriods = state => state.account.settings?.periods
export const getConsequences = state => state.account.settings?.consequences
export const getAddress = state => state.account.settings?.address
export const getHistory = state => state.account.history
export const getAccountName = state => state.account.settings?.name
export const getMessaging = state => state.account.settings?.messaging

export const getSettingsWorking = state => state.account.working

export const getNextConsequence = tardyCount => state => {
  try {
    const consequences = getConsequences(state)
    console.log('tardyCount', tardyCount)
    console.log('consequences ', consequences)
    var nextIndex = Number.parseInt(tardyCount.toString()) % consequences?.length

    if (nextIndex === consequences.length) nextIndex = 0

    return consequences[nextIndex]
  } catch (error) {
    console.error(error)
  }
}

export const getTrialDate = state => {
  if (state.account.settings?.trialEnding != null) {
    const trialDate = new Date(state.account.settings?.trialEnding)
    return trialDate.toLocaleDateString('en-US')
  }
  return null
}

export const getCurrentPeriod = state => {
  var now = new Date()
  var hoursAndMins =
    (now.getHours() < 10 ? '0' : '') +
    now.getHours().toString() +
    (now.getMinutes() < 10 ? '0' : '') +
    now.getMinutes().toString()

  for (var i = 0; i < state.account.settings?.periods.length; i++) {
    var current = state.account.settings?.periods[i]
    var next = state.account.settings?.periods[i + 1]
    if (
      (hoursAndMins >= current && hoursAndMins <= next) ||
      (hoursAndMins >= current && next === undefined)
    ) {
      return i + 1
    }
  }
}
