const requiredFields = ['badgeId', 'lastName', 'firstName']
const optionalFields = [
  'gradeLevel',
  'email',
  'street1',
  'street2',
  'city',
  'state',
  'zipCode',
  'tardyCount',
]

export { requiredFields, optionalFields }
