import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'
import { orange } from '@material-ui/core/colors'
import AlarmIcon from '@material-ui/icons/Alarm'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { getPeriods } from '../../../store/account.slice'

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  iconButton: {
    padding: 10,
    color: orange,
  },
  cardHeader: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  cardAction: {
    alignSelf: 'center',
  },
  listRow: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}))

const ClassPeriodsCard = ({ onUpdate }) => {
  const classes = useStyles()
  const periods = useSelector(getPeriods)

  const handleDateChange = (newTime, index) => {
    const tempPeriods = [...periods]
    const newHour = dayjs(newTime).format('HH')
    const newMinute = dayjs(newTime).format('mm')

    tempPeriods[index] = newHour.concat(newMinute)

    onUpdate(tempPeriods)
  }

  return (
    <Card variant='outlined'>
      <CardHeader
        className={classes.cardHeader}
        title='Class Periods'
        classes={{
          title: classes.cardHeader,
          action: classes.cardAction,
        }}
        avatar={
          <Avatar className={classes.avatar}>
            <AlarmIcon />
          </Avatar>
        }
      />
      {periods && (
        <CardContent>
          <Grid container>
            {periods != null &&
              periods.map((period, index) => {
                return (
                  <Grid item xs={12} key={'time-picker-grid' + index}>
                    <TimePicker
                      ampm={true}
                      clearable
                      key={'time-picker' + index}
                      id={'time-picker' + index}
                      label={`Period ` + (index + 1)}
                      value={dayjs()
                        .hour(period.substring(0, 2))
                        .minute(period.substring(2, 4))}
                      onChange={event => handleDateChange(event, index)}
                    />
                  </Grid>
                )
              })}
          </Grid>
        </CardContent>
      )}
    </Card>
  )
}

export default ClassPeriodsCard
