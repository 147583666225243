import React, { useEffect } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { Link } from 'react-router-dom'
import Navigation from '../Navigation'
import SignUpPage from '../../../pages/SignUp'
import SignInPage from '../../../pages/SignIn'
import PasswordForgetPage from '../../../pages/PasswordForget'
import ScanPage from '../../../pages/Scan'
import AccountPage from '../../../pages/Account'
import AdminPage from '../../../pages/Admin'
import StudentsPage from '../../../pages/Students'
import AccountHistoryPage from '../../../pages/History'
import TardiesPage from '../../../pages/Tardies'
import DetentionsPage from '../../../pages/Detentions'
import OfficeVisitsPage from '../../../pages/OfficeVisits'
import * as ROUTES from '../../../constants/routes'
import { withAuthentication } from '../../higher-order/Session'
import { SnackbarAlert } from '../../utility/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkForElectron,
  getAccountId,
  getAuthUser,
  getIsWeb,
  logOut,
} from '../../../store/session.slice'
import { Box, Chip, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import AccountBox from '@material-ui/icons/AccountBox'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import SettingsIcon from '@material-ui/icons/Settings'
import { compose } from '@reduxjs/toolkit'
import { withFirebase } from '../../higher-order/Firebase'
import { fetchSettings, getAccountName, getTrialDate } from '../../../store/account.slice'
import packageJson from '../../../../package.json'
import AutoUpdater from '../Updater'
import { fetchStudents } from '../../../store/students.slice'
import { ExitToApp } from '@material-ui/icons'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const drawerWidth = 200

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    width: '100%',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  chip: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
  },
  iconRight: {
    float: 'right',
    position: 'relative',
  },
}))

const App = props => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const isWeb = useSelector(getIsWeb)
  const authUser = useSelector(getAuthUser)
  const trialDate = useSelector(getTrialDate)
  const accountId = useSelector(getAccountId)
  const accountName = useSelector(getAccountName)

  const dispatch = useDispatch()
  const menuOpen = Boolean(anchorEl)

  useEffect(() => {
    dispatch(checkForElectron())
    document.title = 'Tardy App'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (accountId != null) {
      dispatch(fetchSettings({ accountId, firebase: props.firebase }))
      dispatch(fetchStudents({ accountId, firebase: props.firebase }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSignOut = () => {
    dispatch(logOut({ firebase: props.firebase }))
  }

  return (
    <Router forceRefresh={true}>
      <div className={classes.root}>
        <AppBar
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <AutoUpdater />
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' noWrap style={{ flex: 1 }}>
              {!accountName && <span>Simple School Solutions - </span>}
              Tardy App
              {accountName && <span> - {accountName}</span>}
            </Typography>
            {trialDate != null && (
              <Box component='span' m={1} className={classes.box}>
                <Chip
                  className={classes.chip}
                  label={`Trial ending: ${trialDate}`}
                  color='secondary'
                />
              </Box>
            )}
            <Box component='span' m={1} className={classes.box}>
              <Chip
                className={classes.chip}
                label={`${isWeb ? 'Web' : 'Desktop'}`}
                variant='outlined'
                color='secondary'
              />
            </Box>
            {authUser && (
              <div className={classes.iconRight}>
                <Box component='span' m={1} className={classes.box}>
                  <Chip
                    icon={<AccountCircleIcon />}
                    label={authUser?.email}
                    clickable
                    color='primary'
                    onClick={handleMenu}
                    onDelete={handleMenu}
                    deleteIcon={<ArrowDropDownIcon />}
                  />
                </Box>
                {/* <IconButton
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleMenu}
                  color='inherit'
                >
                  <SettingsIcon />
                </IconButton> */}
                <Menu
                  getContentAnchorEl={null}
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={menuOpen}
                  onClose={handleClose}
                >
                  {(authUser.role === 'ACCOUNTHOLDER' || authUser.role === 'ADMIN') && (
                    <MenuItem onClick={handleClose}>
                      <Link to={ROUTES.ADMIN}>
                        <ListItem button key={ROUTES.ADMIN + 'header-BTN'}>
                          <ListItemIcon>
                            <SettingsIcon />
                          </ListItemIcon>
                          <ListItemText primary='Account Settings' />
                        </ListItem>
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleClose}>
                    <Link to={ROUTES.ACCOUNT}>
                      <ListItem button key={ROUTES.ACCOUNT + 'header-BTN'}>
                        <ListItemIcon>
                          <AccountBox />
                        </ListItemIcon>
                        <ListItemText primary='User Profile' />
                      </ListItem>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleSignOut}>
                    <Link to={ROUTES.SIGN_IN}>
                      <ListItem button key={ROUTES.SIGN_IN + 'header-BTN'}>
                        <ListItemIcon>
                          <ExitToApp />
                        </ListItemIcon>
                        <ListItemText primary='Sign Out' />
                      </ListItem>
                    </Link>
                  </MenuItem>
                  <MenuItem>Version {packageJson.version}</MenuItem>
                </Menu>
              </div>
            )}
            {!authUser && (
              <Link to={ROUTES.SIGN_IN}>
                <IconButton aria-haspopup='false' color='secondary'>
                  <LockOpenIcon />
                </IconButton>
              </Link>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />

          <Navigation />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            {/* <Route exact path={ROUTES.LANDING} component={LandingPage} /> */}
            <Route exact path={ROUTES.LANDING} component={SignInPage} />
            <Route path={ROUTES.ACCOUNT_INVITE} component={SignUpPage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
            <Route path={ROUTES.SCAN} component={ScanPage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
            <Route path={ROUTES.STUDENTS} component={StudentsPage} />
            <Route path={ROUTES.ACCOUNT_HISTORY} component={AccountHistoryPage} />
            <Route path={ROUTES.TARDIES} component={TardiesPage} />
            <Route path={ROUTES.DETENTIONS} component={DetentionsPage} />
            <Route path={ROUTES.OFFICE_VISITS} component={OfficeVisitsPage} />
          </Switch>
        </main>
      </div>
      );
      <SnackbarAlert />
    </Router>
  )
}

export default compose(withFirebase, withAuthentication)(App)
