import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { ipcRenderer } from '../../../renderer'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  notification: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    width: '200px',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
  },
  message: {
    color: '#3f24d6',
  },
}))

const AutoUpdater = () => {
  const classes = useStyles()

  const [show, setShow] = useState(true)
  const [message, setMessage] = useState(null)
  const [showRestart, setShowRestart] = useState(false)

  if (ipcRenderer) {
    ipcRenderer.on('update_available', () => {
      ipcRenderer.removeAllListeners('update_available')
      setMessage('A new update is available. Downloading now...')
      setShow(true)
    })

    ipcRenderer.on('update_downloaded', () => {
      ipcRenderer.removeAllListeners('update_downloaded')
      setShowRestart(true)
      setMessage('Update Downloaded. It will be installed on restart. Restart now?')
      setShow(true)
    })
  }

  const closeNotification = () => {
    setShow(false)
  }

  const restartApp = () => {
    if (ipcRenderer) {
      ipcRenderer.send('restart_app')
    }
  }

  return !message
    ? null
    : show && ipcRenderer && (
        <div id='notification' className={classes.notification}>
          <p className={classes.message} id='message'>
            {message}
          </p>
          <Button size='small' color='secondary' id='close-button' onClick={closeNotification}>
            Close
          </Button>
          {showRestart && (
            <Button
              size='small'
              variant='outlined'
              color='primary'
              id='restart-button'
              onClick={restartApp}
            >
              Restart
            </Button>
          )}
        </div>
      )
}

export default AutoUpdater
