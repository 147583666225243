import React, { useEffect, useState } from 'react'
import { withAuthorization } from '../../components/higher-order/Session'
import { Scanner, ScannerPreview } from '../../components/utility/Scanner'
import { TardyAddModal } from '../../components/utility/Modals'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedStudent, setSelectedStudent } from '../../store/students.slice'
import { compose } from 'redux'
import { withFirebase } from '../../components/higher-order/Firebase'

const ScanPage = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  // const [scannedStudent, setScannedStudent] = useState(null)
  const dispatch = useDispatch()
  const selectedStudent = useSelector(getSelectedStudent)

  const handleScanMatch = student => {
    // here student will already be set by reducer
    if (student) {
      setModalIsOpen(true)
    }
  }

  useEffect(() => {
    if (selectedStudent != null) {
      handleScanMatch(selectedStudent)
    }
  }, [selectedStudent])

  const handleModalClose = () => {
    dispatch(setSelectedStudent(null))
    setModalIsOpen(false)
  }

  const handleManualSelection = student => {
    dispatch(setSelectedStudent(student))
  }

  return (
    <React.Fragment>
      <Scanner />
      <ScannerPreview onSelected={student => handleManualSelection(student)} />

      {modalIsOpen && selectedStudent && (
        <TardyAddModal
          readonly={false}
          open={modalIsOpen}
          student={{ ...selectedStudent }}
          handleClose={handleModalClose}
        />
      )}
    </React.Fragment>
  )
}
const condition = authUser => authUser

export default compose(withFirebase, withAuthorization(condition))(ScanPage)
