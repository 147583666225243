import {
  defaultDetentionMessageDescription,
  defaultDetentionMessageTitle,
  defaultOfficeReferralMessageDescription,
  defaultOfficeReferralMessageTitle,
  defaultTardyMessageDescription,
  defaultTardyMessageTitle,
} from '../constants/messaging'
import {
  addStudentFromListener,
  deleteStudentFromListener,
  updateStudentFromListener,
} from '../store/students.slice'
import * as ROLES from '../constants/roles'

export const ToDateTime = timestamp => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(timestamp)
}

export const getDefaultPeriods = () => {
  return ['0700', '0815', '0930', '1100', '1245', '1390', '1520']
}

export const getDefaultConsequences = () => {
  return [
    { name: 'tardy' },
    { name: 'tardy' },
    { name: 'tardy' },
    { name: 'detention' },
    { name: 'office' },
  ]
}

export const getDefaultMessaging = () => {
  return {
    tardyTitle: defaultTardyMessageTitle,
    tardyDescription: defaultTardyMessageDescription,
    detentionTitle: defaultDetentionMessageTitle,
    detentionDescription: defaultDetentionMessageDescription,
    officeTitle: defaultOfficeReferralMessageTitle,
    officeDescription: defaultOfficeReferralMessageDescription,
  }
}

export const setupStudentsListener = (firebase, accountId, dispatch) => {
  return firebase
    .students()
    .where('accountId', '==', accountId)
    .onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') dispatch(addStudentFromListener(change.doc.data()))
        if (change.type === 'modified') dispatch(updateStudentFromListener(change.doc.data()))
        if (change.type === 'removed') dispatch(deleteStudentFromListener(change.doc.data()))
      })
    })
}

export const isAnyUser = authUser => {
  return authUser != null
}

export const isAdmin = authUser => {
  return authUser && (authUser.role === ROLES.ACCOUNTHOLDER || ROLES.ADMIN)
}

export const isAccountHolder = authUser => {
  return authUser && authUser.role === ROLES.ACCOUNTHOLDER
}
