import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  makeStyles,
  TextField,
  Container,
  Typography,
  LinearProgress,
} from '@material-ui/core'

import { withFirebase } from '../../components/higher-order/Firebase'
import * as ROUTES from '../../constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { getIsAccountWorking, resetPassword } from '../../store/account.slice'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 30),
    verticalAlign: 'center',
  },
}))

const PasswordForgetPage = () => (
  <div>
    <h1>Reset Password</h1>
    <PasswordForgetForm />
  </div>
)

const PasswordForgetFormBase = props => {
  const [email, setEmail] = useState('')
  const [isValid, setIsValid] = useState(false)
  const working = useSelector(getIsAccountWorking)
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    setIsValid(email !== '')
  }, [email])

  const onSubmit = event => {
    event.preventDefault()

    if (isValid) {
      dispatch(resetPassword({ firebase: props.firebase, email }))
    }
  }

  const handleUpdateEmail = event => {
    setEmail(event.target.value)
  }

  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          Reset Password
        </Typography>
        <form onSubmit={onSubmit}>
          <TextField
            name='email'
            value={email}
            onChange={e => handleUpdateEmail(e)}
            type='email'
            label='Email Address'
            margin='normal'
            fullWidth
            variant='outlined'
            autoComplete='email'
          />
          <Button variant='contained' color='primary' disabled={!isValid} type='submit'>
            Reset My Password
          </Button>
          {working && <LinearProgress />}
        </form>
      </div>
    </Container>
  )
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
)

export default PasswordForgetPage

const PasswordForgetForm = withFirebase(PasswordForgetFormBase)

export { PasswordForgetForm, PasswordForgetLink }
