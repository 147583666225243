import React from 'react'
import { withAuthorization } from '../../components/higher-order/Session'
import { withFirebase } from '../../components/higher-order/Firebase'
import compose from 'recompose/compose'
import { Container } from '@material-ui/core'
import { StudentGrid } from '../../components/utility/Grids'
import { useSelector } from 'react-redux'
import { allStudents } from '../../store/students.slice'

const StudentsPage = props => {
  const students = useSelector(allStudents)

  return (
    <Container component='main' maxWidth='lg'>
      {students && <StudentGrid students={[...students]} />}
    </Container>
  )
}

const condition = authUser => authUser

export default compose(withAuthorization(condition), withFirebase)(StudentsPage)
