import React, { useEffect, useState } from 'react'
import { Link, useParams, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../../components/higher-order/Firebase'
import { SignInLink } from '../SignIn'
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Avatar,
  Grid,
  Container,
  Box,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import * as ROUTES from '../../constants/routes'
import { Copyright } from '../../components/utility/Copyright'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthUser, getIsLoggedIn, getIsLoggingInOut, signUp } from '../../store/session.slice'
import { acceptInvite } from '../../store/users.slice'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const SignUpPage = () => (
  <Container component='main' maxWidth='xs'>
    <SignUpForm />
    <SignInLink />
    <Box mt={8}>
      <Copyright />
    </Box>
  </Container>
)

const SignUpFormBase = props => {
  // const [username, setUsername] = useState('')
  const [accountName, setAccountName] = useState('')
  const [email, setEmail] = useState('')
  const [passwordOne, setPasswordOne] = useState('')
  const [passwordTwo, setPasswordTwo] = useState('')
  const dispatch = useDispatch()
  const { account, id, name } = useParams()
  const authUser = useSelector(getAuthUser)
  const loggedIn = useSelector(getIsLoggedIn)
  const loading = useSelector(getIsLoggingInOut)

  const isInvalid =
    passwordOne !== passwordTwo || passwordOne === '' || email === '' || accountName === ''
  const classes = useStyles()

  useEffect(() => {
    console.log('auth user changed')

    if (authUser) {
      console.log('autUser ', authUser)
      console.log('loggedIn ', loggedIn)
      if (authUser.role === 'ADMIN' || authUser.role === 'ACCOUNTHOLDER') {
        console.info('routing to admin')
        props.history.push(ROUTES.ADMIN)
      } else {
        console.info('routing to scan')
        props.history.push(ROUTES.SCAN)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, loggedIn])

  useEffect(() => {
    if (name != null) {
      const decodedName = decodeURIComponent(name)
      console.log('decoded name: ', decodedName)
      // console.log('setaccountname ', decodedName)
      setAccountName(decodedName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  const onSubmit = event => {
    event.preventDefault()
    if (account !== undefined) {
      dispatch(
        acceptInvite({
          email,
          id: id,
          pass: passwordOne,
          firebase: props.firebase,
          accountId: account,
        })
      )
    } else {
      dispatch(
        signUp({ email, pass: passwordOne, firebase: props.firebase, accountName: accountName })
      )
    }
  }

  return (
    <div className={classes.paper}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      {name == null && (
        <Typography component='h1' variant='h5'>
          Sign Up
        </Typography>
      )}
      {name != null && (
        <Typography component='h1' variant='h5'>
          Accept Invite
        </Typography>
      )}
      <form onSubmit={onSubmit}>
        <TextField
          name='accountName'
          value={accountName}
          onChange={e => setAccountName(e.target.value)}
          type='text'
          label='School Name'
          variant='outlined'
          margin='normal'
          required
          autoComplete='name'
          fullWidth
          autoFocus
          disabled={name != null}
        />
        <TextField
          name='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          type='text'
          label='Email Address'
          variant='outlined'
          margin='normal'
          required
          autoComplete='email'
          fullWidth
          disabled={authUser != null}
        />
        <TextField
          name='passwordOne'
          value={passwordOne}
          onChange={e => setPasswordOne(e.target.value)}
          type='password'
          label='Password'
          variant='outlined'
          margin='normal'
          required
          autoComplete='new-password'
          fullWidth
        />
        <TextField
          name='passwordTwo'
          value={passwordTwo}
          onChange={e => setPasswordTwo(e.target.value)}
          type='password'
          label='Confirm Password'
          variant='outlined'
          margin='normal'
          required
          autoComplete='new-password'
          fullWidth
        />
        {/* <TextField
          name='isAdmin'
          select
          label='Select a Role'
          value={role}
          onChange={e => setRole(e.target.value)}
          variant='outlined'
          margin='normal'
          required
          fullWidth
        >
          <MenuItem key={ROLES.USER} value={ROLES.USER}>
            User
          </MenuItem>
          <MenuItem key={ROLES.STATION} value={ROLES.STATION}>
            Manager
          </MenuItem>
          <MenuItem key={ROLES.ADMIN} value={ROLES.ADMIN}>
            Admin
          </MenuItem>
        </TextField> */}
        <Button
          variant='contained'
          color='primary'
          disabled={isInvalid}
          type='submit'
          fullWidth
          className={classes.submit}
        >
          Sign Up
        </Button>
      </form>
    </div>
  )
}

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase)

const SignUpLink = () => (
  <Grid container>
    <Grid item xs>
      Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </Grid>
  </Grid>
)

export default SignUpPage

export { SignUpForm, SignUpLink }
