import { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { withAuthorization } from '../../higher-order/Session'
import {
  Grid,
  makeStyles,
  Container,
  TextField,
  Card,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from '@material-ui/core'
import { getAccountId } from '../../../store/session.slice'
import { useDispatch, useSelector } from 'react-redux'
import { withFirebase } from '../../higher-order/Firebase'
import { fetchSettings, getMessaging, updateMessaging } from '../../../store/account.slice'
import { isAdmin } from '../../../helpers'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  card: {
    padding: theme.spacing(4, 4, 4),
  },
  input: {
    padding: theme.spacing(2, 2, 2),
  },
}))

const AccountMessagingForm = props => {
  const accountId = useSelector(getAccountId)
  const accountMessaging = useSelector(getMessaging)
  const [workingEdits, setWorkingEdits] = useState(null)

  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    dispatch(fetchSettings({ firebase: props.firebase, accountId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setWorkingEdits(accountMessaging)
  }, [accountMessaging])

  const handleUpdate = evt => {
    const newEdits = { ...workingEdits }
    newEdits[evt.target.name] = evt.target.value
    setWorkingEdits(newEdits)
  }

  const handleSave = () => {
    dispatch(
      updateMessaging({
        updatedMessaging: { ...workingEdits },
        accountId,
        firebase: props.firebase,
      })
    )
  }

  return (
    <Container className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          {workingEdits && (
            <Card className={classes.card}>
              <FormGroup>
                <FormLabel>Tardy</FormLabel>
                <FormControl className={classes.input}>
                  <TextField
                    id='messaging-title'
                    label='Tardy Title'
                    value={workingEdits['tardyTitle']}
                    variant='outlined'
                    name='tardyTitle'
                    onChange={handleUpdate}
                  />
                </FormControl>
                <FormControl className={classes.input}>
                  {workingEdits && (
                    <TextField
                      fullWidth
                      id='messaging-title'
                      label='Tardy Description'
                      multiline
                      rows={3}
                      value={workingEdits['tardyDescription']}
                      name='tardyDescription'
                      onChange={handleUpdate}
                      variant='outlined'
                    />
                  )}
                </FormControl>
                <FormControl>
                  {workingEdits && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleSave}
                      disabled={
                        workingEdits['tardyTitle'] === accountMessaging['tardyTitle'] &&
                        workingEdits['tardyDescription'] === accountMessaging['tardyDescription']
                      }
                    >
                      Save
                    </Button>
                  )}
                </FormControl>
              </FormGroup>
            </Card>
          )}
        </Grid>
        <Grid item xs={6}>
          {workingEdits && (
            <Card className={classes.card}>
              <FormGroup>
                <FormLabel>Detention</FormLabel>
                <FormControl className={classes.input}>
                  {workingEdits && (
                    <TextField
                      id='detention-title'
                      label='Detention Title'
                      value={workingEdits['detentionTitle']}
                      variant='outlined'
                      name='detentionTitle'
                      onChange={handleUpdate}
                    />
                  )}
                </FormControl>
                <FormControl className={classes.input}>
                  {workingEdits && (
                    <TextField
                      fullWidth
                      id='detention-description'
                      label='Detention Description'
                      multiline
                      rows={3}
                      value={workingEdits['detentionDescription']}
                      variant='outlined'
                      name='detentionDescription'
                      onChange={handleUpdate}
                    />
                  )}
                </FormControl>
                <FormControl>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSave}
                    disabled={
                      workingEdits['detentionTitle'] === accountMessaging['detentionTitle'] &&
                      workingEdits['detentionDescription'] ===
                        accountMessaging['detentionDescription']
                    }
                  >
                    Save
                  </Button>
                </FormControl>
              </FormGroup>
            </Card>
          )}
        </Grid>
        <Grid item xs={6}>
          {workingEdits && (
            <Card className={classes.card}>
              <FormGroup>
                <FormLabel>Office Referral</FormLabel>
                <FormControl className={classes.input}>
                  {workingEdits && (
                    <TextField
                      id='office-title'
                      label='Office Referral Title'
                      value={workingEdits['officeTitle']}
                      variant='outlined'
                      name='officeTitle'
                      onChange={handleUpdate}
                    />
                  )}
                </FormControl>
                <FormControl className={classes.input}>
                  {workingEdits && (
                    <TextField
                      fullWidth
                      id='office-description'
                      label='Office Referral Description'
                      multiline
                      rows={3}
                      value={workingEdits['officeDescription']}
                      variant='outlined'
                      name='officeDescription'
                      onChange={handleUpdate}
                    />
                  )}
                </FormControl>
                <FormControl>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSave}
                    disabled={
                      workingEdits['officeTitle'] === accountMessaging['officeTitle'] &&
                      workingEdits['officeDescription'] === accountMessaging['officeDescription']
                    }
                  >
                    Save
                  </Button>
                </FormControl>
              </FormGroup>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

const condition = authUser => isAdmin(authUser)

export default compose(withAuthorization(condition), withFirebase)(AccountMessagingForm)
