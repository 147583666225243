import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../../components/higher-order/Firebase'
import { withAuthorization } from '../../components/higher-order/Session'
import AccountSettings from '../../components/utility/AccountSettings'
import StudentUploadForm from '../../components/utility/StudentUpload'
import AdminActions from '../../components/utility/AdminActions'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers, allUsers } from '../../store/users.slice'
import PrinterSettingsForm from '../../components/utility/PrinterSettings'
import { UserGrid } from '../../components/utility/Grids'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getAccountId } from '../../store/session.slice'
import { fetchTardies } from '../../store/tardies.slice'
import { fetchDetentions } from '../../store/detentions.slice'
import { fetchOfficeVisits } from '../../store/officeVisits.slice'
import AccountMessaging from '../../components/utility/AccountMessaging'
import { isAdmin } from '../../helpers'

const views = () => {
  return [
    {
      label: 'Account Settings',
      secondaryLabel: 'Set consequences, class periods, and address details',
      // description: 'detailed description TODO',
      item: <AccountSettings />,
    },
    {
      label: 'Account Messaging',
      secondaryLabel: 'Set tardy, detention, and office referral messaging for printed passes',
      // description: 'detailed description TODO',
      item: <AccountMessaging />,
    },
    {
      label: 'Printer Settings',
      secondaryLabel: 'Change default tardy printer',
      // description: 'detailed description TODO',
      item: <PrinterSettingsForm />,
    },
    {
      label: 'User Management',
      secondaryLabel: 'Create, invite, and edit user access',
      // description: 'detailed description TODO',
      item: <UserGrid />,
    },
    {
      label: 'Student Upload',
      secondaryLabel: 'Use a template to upload students',
      // description: 'detailed description TODO',
      item: <StudentUploadForm />,
    },
    {
      label: 'Admin Actions',
      secondaryLabel: 'Reset tardy counts and delete students/tardies/consequences',
      // description: 'detailed description TODO',
      item: <AdminActions />,
    },
  ]
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}))

const AdminPage = props => {
  const classes = useStyles()
  const users = useSelector(allUsers)
  const [adminViews, setAdminViews] = useState([views([users])])
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState(false)
  const accountId = useSelector(getAccountId)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    dispatch(fetchTardies({ firebase: props.firebase, accountId: accountId }))
    dispatch(fetchDetentions({ firebase: props.firebase, accountId: accountId }))
    dispatch(fetchOfficeVisits({ firebase: props.firebase, accountId: accountId }))
    dispatch(fetchUsers({ firebase: props.firebase, accountId: accountId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAdminViews(views({ ...users }))
  }, [users])

  return (
    <div className={classes.root}>
      <h1>Admin</h1>
      {adminViews.map(av => {
        return (
          <Accordion
            expanded={expanded === av.label}
            onChange={handleChange(av.label)}
            TransitionProps={{ unmountOnExit: true }}
            key={av.label + av.description}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id={av.label}
            >
              <Typography className={classes.heading}>{av.label}</Typography>
              <Typography className={classes.secondaryHeading}>{av.secondaryLabel}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{av.description}</Typography>
              {av.item}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}

const condition = authUser => isAdmin(authUser)

export default compose(withAuthorization(condition), withFirebase)(AdminPage)
