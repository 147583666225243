import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit'
import { addDetention } from './detentions.slice'
import { addOfficeVisit } from './officeVisits.slice'
import { addTardy, deleteTardy } from './tardies.slice'

const studentsAdapter = createEntityAdapter(
  // can override these selectors, like if id is studentId
  {
    selectId: student => student.badgeId,
  }
)

const initialState = studentsAdapter.getInitialState({
  loading: false,
  updating: false,
  searching: false,
  deleting: false,
  uploading: false,
  selected: null,
  searchTerm: '',
  searchResults: [],
  resettingTardies: false,
  upload: {
    valid: [],
    invalid: [],
    totalToValidate: null,
    validating: false,
    uploadedCount: 0,
  },
})

export const fetchStudents = createAsyncThunk(
  'students/fetchStudents',
  // todo: shouldn't need firebase passed in
  async (args, thunkAPI) => {
    var state = thunkAPI.getState()
    if (state.students.ids?.length === 0) {
      try {
        return await args.firebase.doFetchStudents(args.accountId)
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

// export const fetchStudentById = createAsyncThunk(
//   'students/fetchStudentById',
//   // todo: shouldn't need firebase passed in
//   async (args, thunkAPI) => {
//     var state = thunkAPI.getState()
//     if (state.students.ids?.length === 0) {
//       try {
//         return await args.firebase.doFetchStudentById(args.badgeId)
//       } catch (error) {
//         return thunkAPI.rejectWithValue(error.message)
//       }
//     } else {
//       return Object.values(state.students.entities).find(s => s.badgeId === args.id)
//     }
//   }
// )

export const deleteStudent = createAsyncThunk('students/deleteStudent', async (args, thunkAPI) => {
  try {
    return await args.firebase.doDeleteStudent(args.student)
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const uploadStudent = createAsyncThunk('students/uploadOne', async (args, thunkAPI) => {
  try {
    return await args.firebase.doUploadStudent(args.student, args.accountId)
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const uploadStudents = createAsyncThunk('students/upload', async (args, thunkAPI) => {
  try {
    return await args.firebase.doUploadStudents(args.students, args.accountId)
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const deleteAllStudents = createAsyncThunk(
  'students/deleteAllStudents',
  async (args, thunkAPI) => {
    try {
      return await args.firebase.doDeleteAllStudents()
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const resetTardyCounts = createAsyncThunk(
  'students/resetTardyCounts',
  async (args, thunkAPI) => {
    try {
      const res = await args.firebase.doResetTardyCounts()
      return { students: res, message: 'all tardy counts reset' }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateStudent = createAsyncThunk('students/updateStudent', async (args, thunkAPI) => {
  try {
    const student = await args.firebase.doUpsertStudent(args.student)
    return {
      student,
      message: 'student updated',
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    addStudentFromListener: (state, action) => {
      studentsAdapter.addOne(state, action.payload)
    },
    updateStudentFromListener: (state, action) => {
      studentsAdapter.upsertOne(state, action.payload)
    },
    deleteStudentFromListener: (state, action) => {
      studentsAdapter.removeOne(state, action.payload)
    },
    setSelectedStudent: (state, action) => {
      state.selected = action.payload
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setTotalUploadsToValidate: (state, action) => {
      state.upload.totalToValidate = action.payload
    },
    setIsValidating: (state, action) => {
      state.upload.validating = action.payload
    },
    addValidUpload: (state, action) => {
      state.upload.valid.push(action.payload)
    },
    addInvalidUpload: (state, action) => {
      state.upload.invalid.push(action.payload)
    },
    setIsUploading: (state, action) => {
      state.upload.working = true
    },
    setFinishedUploading: (state, action) => {
      state.upload.working = false
      state.upload.complete = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStudents.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload) studentsAdapter.setAll(state, action.payload)
      })
      .addCase(addTardy.fulfilled, (state, action) => {
        var { student } = action.payload
        studentsAdapter.upsertOne(state, student)
        state.loading = false
        state.searchTerm = ''
      })
      .addCase(addDetention.fulfilled, (state, action) => {
        var { student } = action.payload
        studentsAdapter.upsertOne(state, student)
        state.loading = false
        state.searchTerm = ''
      })
      .addCase(addOfficeVisit.fulfilled, (state, action) => {
        var { student } = action.payload
        studentsAdapter.upsertOne(state, student)
        state.loading = false
        state.searchTerm = ''
      })
      .addCase(deleteTardy.fulfilled, (state, action) => {
        var { student } = action.payload
        studentsAdapter.upsertOne(state, student)
        state.loading = false
      })
      .addCase(updateStudent.pending, (state, action) => {
        state.updating = true
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        var { student } = action.payload
        studentsAdapter.upsertOne(state, student)
        state.updating = false
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.updating = false
      })
      .addCase(deleteStudent.pending, (state, action) => {
        state.deleting = true
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        var { id } = action.payload
        studentsAdapter.removeOne(state, id)
        state.deleting = false
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.deleting = false
      })
      .addCase(resetTardyCounts.pending, (state, action) => {
        state.resettingTardies = true
      })
      .addCase(resetTardyCounts.fulfilled, (state, action) => {
        const { students } = action.payload
        state.resettingTardies = false

        if (action.payload) studentsAdapter.setAll(state, students)
      })
      .addCase(resetTardyCounts.rejected, (state, action) => {
        state.resettingTardies = false
      })
      .addCase(deleteAllStudents.rejected, (state, action) => {
        state.deleting = false
      })

      .addCase(deleteAllStudents.pending, (state, action) => {
        state.deleting = true
      })
      .addCase(deleteAllStudents.fulfilled, (state, action) => {
        state.deleting = false
        studentsAdapter.removeAll(state)
      })
      .addCase(uploadStudent.fulfilled, (state, action) => {
        const { student } = action.payload
        var uploadStudent = state.upload.valid.find(s => s.badgeId === student.badgeId)

        uploadStudent.createdOn = student.createdOn
        state.upload.uploadedCount += 1
        studentsAdapter.upsertOne(state, student)
      })
      // .addCase(uploadStudents.pending, (state, action) => {
      //   state.uploading = true
      // })
      // .addCase(uploadStudents.fulfilled, (state, action) => {
      //
      //   const { students } = action.payload
      //   state.uploading = false
      //   studentsAdapter.upsertMany(state, students)
      // })
      // .addCase(uploadStudents.rejected, (state, action) => {
      //   state.uploading = false
      // })
      .addMatcher(
        isAnyOf(fetchStudents.pending, addTardy.pending, deleteTardy.pending),
        (state, action) => {
          state.loading = true
        }
      )
      .addMatcher(
        isAnyOf(fetchStudents.rejected, addTardy.rejected, deleteTardy.rejected),
        (state, action) => {
          state.loading = false
        }
      )
  },
})

export const {
  addValidUpload,
  addInvalidUpload,
  addStudentFromListener,
  updateStudentFromListener,
  deleteStudentFromListener,
  setSelectedStudent,
  setStudentsError,
  setSearchTerm,
  setIsValidating,
  setTotalUploadsToValidate,
  setIsUploading,
  setFinishedUploading,
} = studentsSlice.actions

export default studentsSlice.reducer

export const {
  selectAll: selectAllStudents,
  selectById: selectStudentById,
} = studentsAdapter.getSelectors(state => state.students)

export const getStudentsState = state => state
export const getStudentsCount = state => state.students.ids.length
export const getStudentsLoading = state => state.students.loading
export const getStudentsDeleting = state => state.students.deleting
export const getStudentsError = state => state.students.error
export const getSelectedStudent = state => state.students.selected
export const getSearchTerm = state => state.students.searchTerm
export const getResettingTardyCounts = state => state.students.resettingTardies

export const getStudentsUploading = state => state.students.upload.working
export const getStudentsUploadComplete = state => state.students.upload.complete
export const getStudentsUploadedCount = state => state.students.upload.uploadedCount
export const getUploadValidCount = state => state.students.upload.valid.length
export const getUploadInvalidCount = state => state.students.upload.invalid.length
export const getUploadTotalValidatedCount = state =>
  state.students.upload.valid.length + state.students.upload.invalid.length
export const getValidUploads = state => state.students.upload.valid
export const getInvalidUploads = state => state.students.upload.invalid
export const getAllValidatedUploads = state =>
  state.students.upload.valid.concat(state.students.upload.invalid)
export const getIsValidatingUploads = state => state.students.upload.validating
export const getTotalUploadsToValidate = state => state.students.upload.totalToValidate

export const getFilteredStudents = state => {
  if (state.students.searchTerm !== '') {
    var vals = Object.values(state.students.entities)
    var results = vals.filter(x => {
      var term = state.students.searchTerm.toLowerCase()

      return (
        x?.badgeId?.toLowerCase().includes(term) ||
        x?.firstName?.toLowerCase().includes(term) ||
        x?.lastName?.toLowerCase().includes(term) ||
        x?.email?.toLowerCase().includes(term)
      )
    })

    return results
  } else return null
}

export const allStudents = createSelector(selectAllStudents, getStudentsState)
