import { makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const useStyles = makeStyles(theme => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
}))

const Dropzone = props => {
  const classes = useStyles()
  const onDrop = useCallback(
    acceptedFile => {
      props.handle(acceptedFile)
    },
    [props]
  )
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'text/csv, application/vnd.ms-excel',
    maxFiles: 1,
    onDrop,
  })

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  return (
    <section className='container'>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <p>Drag and drop files here, or click to select files</p>
        <em>(Only *.csv will be accepted)</em>
      </div>
      <aside>
        <ul>{acceptedFileItems}</ul>
        <ul>{fileRejectionItems}</ul>
      </aside>
    </section>
  )
}

export default Dropzone
