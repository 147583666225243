import {
  Button,
  Container,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { withFirebase } from '../../components/higher-order/Firebase'
import { useDispatch } from 'react-redux'
import { changePassword } from '../../store/account.slice'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 30),
    verticalAlign: 'center',
  },
}))

const PasswordChangeForm = props => {
  const [passOne, setPassOne] = useState('')
  const [passTwo, setPassTwo] = useState('')
  const [isValid, setIsValid] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()

  const onSubmit = event => {
    event.preventDefault()

    if (isValid) {
      dispatch(changePassword({ pass: passOne, firebase: props.firebase }))
    }
  }

  useEffect(() => {
    if (passOne === passTwo && passOne !== '' && passTwo !== '') {
      setIsValid(true)
    }
  }, [passOne, passTwo])

  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          Change Password
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            name='passwordOne'
            variant='outlined'
            margin='normal'
            value={passOne}
            onChange={e => setPassOne(e.target.value)}
            type='password'
            label='New Password'
            fullWidth
            autoComplete='password'
          />
          <TextField
            name='passwordTwo'
            variant='outlined'
            value={passTwo}
            margin='normal'
            onChange={e => setPassTwo(e.target.value)}
            type='password'
            label='Confirm New Password'
            fullWidth
            autoComplete='password'
          />
          <Button
            variant='contained'
            color='primary'
            disabled={!isValid}
            type='submit'
          >
            Change Password
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default withFirebase(PasswordChangeForm)
