import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { ipcRenderer } from '../renderer'
import storage from 'redux-persist/lib/storage'
import { acceptInvite } from './users.slice'

const sessionAdapter = createEntityAdapter()

const initialState = sessionAdapter.getInitialState({
  authUser: null,
  printers: [],
  fetchingPrinters: false,
  selectedPrinter: null,
  isWeb: false,
  loggingInOut: false,
  loggedIn: false,
  autoPrint: false,
})

export const checkForElectron = createAsyncThunk('session/checkForElectron', async () => {
  var res = await ipcRenderer
  return res !== undefined
})

export const fetchPrinters = createAsyncThunk('session/fetchPrinters', async (args, thunkAPI) => {
  try {
    return await ipcRenderer.invoke('get-printers')
  } catch (error) {
    return thunkAPI.rejectWithValue('quick print settings unavailable on web')
  }
})

export const printTestPage = createAsyncThunk('session/printTestPage', async (args, thunkAPI) => {
  try {
    var printerName = thunkAPI.getState().session.selectedPrinter.name
    var response = await ipcRenderer.invoke('print-test-page', printerName)
    return { response: response, message: `printing on ${printerName}...` }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const logOut = createAsyncThunk('session/logOut', async (args, thunkAPI) => {
  try {
    await args.firebase.doSignOut()

    return { message: 'successfully logged out' }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const logIn = createAsyncThunk('session/logIn', async (args, thunkAPI) => {
  try {
    return await args.firebase
      .doSignInWithEmailAndPassword(args.email, args.password)
      .then(function (res) {
        return { message: 'welcome back!' }
      })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const signUp = createAsyncThunk('session/signUp', async (args, thunkAPI) => {
  try {
    return await args.firebase
      .doCreateUserWithEmailAndPassword(args.email, args.pass)
      .then(async authUser => {
        const accountId = await args.firebase.doCreateAccount(args.accountName)
        // Create a user in your Firebase realtime database
        return await args.firebase
          .user(authUser.user.uid)
          .set({
            username: '',
            email: authUser.user.email,
            role: 'ACCOUNTHOLDER',
            accountId: accountId,
            authId: authUser.user.uid,
          })
          .then(async res => {
            await args.firebase.doSignInWithEmailAndPassword(args.email, args.pass)
          })
          .then(res => {
            return { message: 'successfully signed up!' }
          })
      })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    authUserSet(state, action) {
      state.authUser = action.payload
    },
    setPrinters(state, action) {
      state.printers = action.payload
    },
    setSelectedPrinter(state, action) {
      state.selectedPrinter = action.payload
    },
    setAutoPrint(state, action) {
      state.autoPrint = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPrinters.pending, (state, action) => {
        state.fetchingPrinters = true
      })
      .addCase(fetchPrinters.fulfilled, (state, action) => {
        state.fetchingPrinters = false
        var arr = action.payload
        arr.push({
          name: 'test_printer_5000',
          displayName: 'Test Printer 5000',
        })
        state.printers = arr
        state.isWeb = false
      })
      .addCase(fetchPrinters.rejected, (state, action) => {
        state.fetchingPrinters = false
        state.isWeb = true
      })
      .addCase(logOut.fulfilled, (state, action) => {
        state.loggingInOut = false
        state.loggedIn = false
        storage.removeItem('persist:root')
      })
      .addCase(checkForElectron.fulfilled, (state, action) => {
        state.isWeb = !action.payload
      })
      .addMatcher(
        isAnyOf(logIn.pending, logOut.pending, signUp.pending, acceptInvite.pending),
        (state, action) => {
          state.loggingInOut = true
          state.authUser = null
          state.loggedIn = false
        }
      )
      .addMatcher(
        isAnyOf(logIn.fulfilled, logOut.rejected, signUp.fulfilled, acceptInvite.fulfilled),
        (state, action) => {
          state.loggingInOut = false

          state.authUser = action.payload.user

          state.loggedIn = true
        }
      )
      .addMatcher(
        isAnyOf(logIn.rejected, signUp.rejected, acceptInvite.rejected),
        (state, action) => {
          state.loggingInOut = false
          state.loggedIn = false
        }
      )
  },
})

export const { authUserSet, setSelectedPrinter, setAutoPrint } = sessionSlice.actions

export default sessionSlice.reducer

export const getSessionState = state => state.session

export const getAuthUser = state => state.session.authUser
export const getAccountId = state => state.session.authUser?.accountId

export const getIsFetchingPrinters = state => state.session.fetchingPrinters
export const getPrinters = state => state.session.printers
export const getIsWeb = state => state.session.isWeb
export const getSelectedPrinter = state => state.session.selectedPrinter
export const getIsLoggingInOut = state => state.session.loggingInOut
export const getIsLoggedIn = state => state.session.loggedIn
export const getShouldAutoPrint = state => state.session.autoPrint
