import { useEffect } from 'react'
import { compose } from 'recompose'
import { withAuthorization } from '../../higher-order/Session'
import { Grid, makeStyles, Container } from '@material-ui/core'
import { ClassPeriodsCard, LocationCard } from '../Cards/index.js'
import ConsequenceGrid from '../Grids/consequenceGrid'
import { getAccountId } from '../../../store/session.slice'
import { useDispatch, useSelector } from 'react-redux'
import { withFirebase } from '../../higher-order/Firebase'
import {
  updateAddress,
  updateClassPeriods,
  fetchSettings,
  getPeriods,
  getAddress,
} from '../../../store/account.slice'
import { fetchStudents } from '../../../store/students.slice'
import { fetchTardies } from '../../../store/tardies.slice'
import { isAdmin } from '../../../helpers'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
}))

const AccountSettingsForm = props => {
  const accountId = useSelector(getAccountId)

  const periods = useSelector(getPeriods)
  const address = useSelector(getAddress)

  const dispatch = useDispatch()

  const persistClassPeriods = updatedPeriods => {
    dispatch(
      updateClassPeriods({
        accountId,
        updatedPeriods,
        firebase: props.firebase,
      })
    )
  }

  const persistLocation = updatedAddress => {
    dispatch(updateAddress({ accountId, updatedAddress, firebase: props.firebase }))
  }

  useEffect(() => {
    dispatch(fetchSettings({ accountId, firebase: props.firebase }))
    dispatch(fetchStudents({ accountId, firebase: props.firebase }))
    dispatch(fetchTardies({ accountId, firebase: props.firebase }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ConsequenceGrid />
        </Grid>
        {periods != null && (
          <Grid item xs={6}>
            <ClassPeriodsCard periods={periods} onUpdate={persistClassPeriods} />
          </Grid>
        )}
        {address != null && (
          <Grid item xs={6}>
            <LocationCard address={address} onUpdate={persistLocation} />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

const condition = authUser => isAdmin(authUser)

export default compose(withAuthorization(condition), withFirebase)(AccountSettingsForm)
