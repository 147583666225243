import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { OfficeReferralEditModal } from '../Modals'
import DeleteIcon from '@material-ui/icons/Delete'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import EditIcon from '@material-ui/icons/Edit'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import BlockIcon from '@material-ui/icons/Block'
import { useDispatch, useSelector } from 'react-redux'
import {
  completeOfficeVisit,
  deleteOfficeVisit,
  getOfficeVisitsLoading,
} from '../../../store/officeVisits.slice'
import { compose } from '@reduxjs/toolkit'
import { withFirebase } from '../../higher-order/Firebase'
import { withAuthorization } from '../../higher-order/Session'

const OfficeVisitGrid = props => {
  const dispatch = useDispatch()
  const [selectedReferral, setSelectedReferral] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const officeVisits = props.officeVisits
  const loading = useSelector(getOfficeVisitsLoading)
  const tableRef = React.useRef()
  const handleSearchChange = event => {
    var count = tableRef.current.state.data.length

    if (count === 1) {
      handleOpenDetails(tableRef.current.state.data[0])
    }
  }

  const handleCompleteOfficeVisit = data => {
    dispatch(
      completeOfficeVisit({
        firebase: props.firebase,
        officeVisitId: data.id,
        studentFirstName: data.studentFirstName,
        studentLastName: data.studentLastName,
      })
    )
  }
  const handleDeleteOfficeVisit = data => {
    dispatch(
      deleteOfficeVisit({
        firebase: props.firebase,
        officeVisitId: data.id,
        studentFirstName: data.studentFirstName,
        studentLastName: data.studentLastName,
      })
    )
  }

  const handleOpenDetails = referral => {
    setSelectedReferral(referral)
    setModalIsOpen(true)
  }

  const handleModalClose = () => {
    setModalIsOpen(false)
  }

  return (
    <React.Fragment>
      {officeVisits && (
        <MaterialTable
          title='Office Referrals'
          isLoading={loading}
          columns={[
            {
              title: 'Completed',
              field: 'completed',
              render: rowData => {
                return rowData.completed ? <CheckCircleIcon /> : <BlockIcon />
              },
            },
            {
              title: 'IssuedSort',
              field: 'timestamp',
              type: 'string',
              hidden: true,
            },
            {
              title: 'Issued',
              field: 'timestampDisplay',
              type: 'datetime',
            },
            {
              title: 'Period',
              field: 'period',
              type: 'numeric',
            },
            {
              title: 'First Name',
              field: 'studentFirstName',
              type: 'string',
            },
            {
              title: 'Last Name',
              field: 'studentLastName',
              type: 'string',
            },
            {
              title: 'Badge Id',
              field: 'badgeId',
              type: 'string',
            },
            {
              title: 'Tardy #',
              field: 'tardyCount',
              type: 'string',
            },
          ]}
          // below is a hack for material-table, since it is adding tabledata to objs in db
          data={officeVisits.map(x => Object.assign({}, x))}
          actions={[
            {
              // iconProps: { style: { color: 'orange' } },
              // icon: 'launch',
              icon: () => <DeleteIcon style={{ color: 'red' }} />,
              tooltip: 'Delete Office Visit',
              onClick: (event, rowData) => {
                handleDeleteOfficeVisit(rowData)
              },
            },
            {
              icon: () => <EditIcon />,
              tooltip: 'Open Details',
              onClick: (event, rowData) => {
                handleOpenDetails(rowData)
              },
            },
            {
              icon: () => <AssignmentTurnedInIcon style={{ color: 'green' }} />,
              tooltip: 'Complete Office Visit',
              onClick: (event, rowData) => {
                handleCompleteOfficeVisit(rowData)
              },
            },
          ]}
          options={{
            paging: true,
            pageSize: 10,
            paginationType: 'normal',
            searchFieldVariant: 'outlined',
            search: true,
            searchAutoFocus: true,
            actionsColumnIndex: -1,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'SSS-TardyApp-OfficeReferralRecords-' + Date().toLocaleLowerCase(),
            dateSetting: 'en-US',
            tableLayout: 'auto',
          }}
          tableRef={tableRef}
          onRowClick={(event, rowData) => handleOpenDetails(rowData)}
          onSearchChange={handleSearchChange}
        />
      )}
      {modalIsOpen && selectedReferral && (
        <OfficeReferralEditModal
          open={modalIsOpen}
          officeReferral={{ ...selectedReferral }}
          handleClose={handleModalClose}
        />
      )}
    </React.Fragment>
  )
}
const condition = authUser => authUser

export default compose(withFirebase, withAuthorization(condition))(OfficeVisitGrid)
