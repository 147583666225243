import { Container } from '@material-ui/core'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getFilteredStudents, getSearchTerm } from '../../../store/students.slice'
import { withFirebase } from '../../higher-order/Firebase'
import { ScannerPreviewGrid } from '../Grids'

const ScannerPreview = props => {
  const results = useSelector(getFilteredStudents)
  const searchTerm = useSelector(getSearchTerm)

  useEffect(() => {
    if (results?.length === 1 && searchTerm !== '') {
      props.onSelected(results[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.length])

  const handleStudentSelected = student => {
    props.onSelected(student)
  }

  if (!results) return null

  return (
    results && (
      //TODO: replace with scanner preview
      <Container component='main' maxWidth='lg'>
        {results && results.length > 0 && (
          <ScannerPreviewGrid
            students={results}
            actionIcon={'launch'}
            actionLabel='Open Details'
            onAction={student => handleStudentSelected(student)}
          />
        )}
      </Container>
    )
  )
}

export default withFirebase(ScannerPreview)
