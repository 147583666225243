import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import {
  TextField,
  Button,
  makeStyles,
  Avatar,
  Grid,
  Container,
  Box,
  Typography,
  CircularProgress,
  Backdrop,
} from '@material-ui/core'
import { SignUpLink } from '../SignUp'
import { PasswordForgetLink } from '../PasswordForget'
import { withFirebase } from '../../components/higher-order/Firebase'
import * as ROUTES from '../../constants/routes'
import { Copyright } from '../../components/utility/Copyright'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthUser, getIsLoggedIn, getIsLoggingInOut, logIn } from '../../store/session.slice'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const SignInPage = () => (
  <Container component='main' maxWidth='xs'>
    <SignInForm />
    <PasswordForgetLink />
    <SignUpLink />
    <Box mt={8}>
      <Copyright />
    </Box>
  </Container>
)

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
}

const SignInFormBase = props => {
  const [email, setEmail] = useState(INITIAL_STATE.email)
  const [password, setPassword] = useState(INITIAL_STATE.password)
  const isLoggingInOrOut = useSelector(getIsLoggingInOut)
  const loggedIn = useSelector(getIsLoggedIn)
  const isInvalid = password === '' || email === ''
  const classes = useStyles()
  const dispatch = useDispatch()
  const authUser = useSelector(getAuthUser)
  const loading = useSelector(getIsLoggingInOut)

  const onSubmit = event => {
    event.preventDefault()
    dispatch(logIn({ email, password, firebase: props.firebase }))
  }

  useEffect(() => {
    console.info('authuser: ', authUser)
    console.info('loggedIn: ', loggedIn)
    if (authUser) {
      console.info('routing to scan')
      props.history.push(ROUTES.SCAN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser])

  return (
    <div className={classes.paper}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component='h1' variant='h5'>
        Sign In
      </Typography>

      <form onSubmit={onSubmit}>
        <TextField
          name='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          type='text'
          label='Email Address'
          variant='outlined'
          margin='normal'
          autoFocus
          required
          autoComplete='email'
          fullWidth
        />
        <TextField
          name='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
          type='password'
          label='Password'
          variant='outlined'
          margin='normal'
          required
          fullWidth
        />
        <Button
          variant='contained'
          color='primary'
          disabled={isInvalid}
          type='submit'
          fullWidth
          className={classes.submit}
        >
          {isLoggingInOrOut && <CircularProgress />}
          Sign In
        </Button>
      </form>
    </div>
  )
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase)

const SignInLink = () => (
  <Grid container>
    <Grid item xs>
      Already have an account? <Link to={ROUTES.SIGN_IN}>Sign In</Link>
    </Grid>
  </Grid>
)

export default SignInPage

export { SignInLink, SignInForm }
