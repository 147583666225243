import React from 'react'
import { withFirebase } from '../Firebase'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { authUserSet } from '../../../store/session.slice'

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)

      this.props.onSetAuthUser(JSON.parse(localStorage.getItem('authUser')))
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          if (authUser != null) {
            localStorage.setItem('authUser', JSON.stringify(authUser))

            this.props.onSetAuthUser(authUser)
          }
        },
        () => {
          localStorage.removeItem('authUser')
          this.props.onSetAuthUser(null)
        }
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return <Component {...this.props} />
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser => dispatch(authUserSet(authUser)),
  })

  return compose(withFirebase, connect(null, mapDispatchToProps))(WithAuthentication)
}

export default withAuthentication
