import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { TardyAddModal } from '../Modals'
import { getAuthUser } from '../../../store/session.slice'

const ScannerPreviewGrid = props => {
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const authUser = useSelector(getAuthUser)
  const tableRef = React.useRef()

  const handleSearchChange = event => {
    var count = tableRef.current.state.data.length

    if (count === 1) {
      handleOpenDetails(tableRef.current.state.data[0])
    }
  }

  const handleOpenDetails = student => {
    setSelectedStudent(student)
    setModalIsOpen(true)
  }

  const handleModalClose = () => {
    setModalIsOpen(false)
  }

  if (!props.students) return null
  return (
    <React.Fragment>
      {props.students && (
        <MaterialTable
          title='Students'
          columns={[
            {
              title: 'First Name',
              field: 'firstName',
              type: 'string',
            },
            {
              title: 'Last Name',
              field: 'lastName',
              type: 'string',
            },
            {
              title: 'Badge Id',
              field: 'badgeId',
              type: 'string',
              defaultSort: 'asc',
            },
            {
              title: 'Email Address',
              field: 'email',
              type: 'string',
              hidden: true,
            },
          ]}
          // below is a hack for material-table, since it is adding tabledata to objs in db
          data={props.students.map(x => Object.assign({}, x))}
          actions={[
            {
              iconProps: { style: { color: 'orange' } },
              icon: 'launch',
              tooltip: 'Open',
              onClick: (event, rowData) => {
                handleOpenDetails(rowData)
              },
            },
            // {
            //   icon: 'add',
            //   tooltip: 'Add Student',
            //   isFreeAction: true,
            //   onClick: () => handleOpenDetails(),
            // },
          ]}
          options={{
            paging: true,
            pageSize: 5,
            paginationType: 'normal',
            searchFieldVariant: 'outlined',
            search: false,
            columnsButton: true,
            searchAutoFocus: true,
            tableLayout: 'auto',
            // searchFieldAlignment: 'center',
          }}
          tableRef={tableRef}
          onRowClick={(event, rowData) => handleOpenDetails(rowData)}
          onSearchChange={handleSearchChange}
        />
      )}
      {modalIsOpen && (
        <TardyAddModal
          readonly={false}
          open={modalIsOpen}
          student={{ ...selectedStudent }}
          authUser={authUser}
          handleClose={handleModalClose}
        />
      )}
    </React.Fragment>
  )
}

export default ScannerPreviewGrid
